/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Avatar, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';

import avatar from '~/assets/defaults/avatar-default.svg';

interface INursery {
  name: string;
}

interface IProjectResponse {
  id: number;
  title: string;
  nursery: INursery;
}

interface IProjectData {
  data: IProjectResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IProject {
  id: string;
  project_id: number;
  title: string;
  nursery: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [projectSelected, setProjectSelected] = useState({} as IProject);

  const loadProjects = useCallback(async (pageData, search = '') => {
    const response = await api.get<IProjectData>('projects', {
      params: { page: pageData, search },
    });

    const data = response.data.data.map<IProject>((project, index) => {
      return {
        id: (pageData * index + 1).toString().padStart(5, '0'),
        project_id: project.id,
        title: project.title,
        nursery: project.nursery.name,
      };
    });

    setProjects(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadProjects(page).finally(() => setLoading(false));
  }, [loadProjects, page]);

  const handleClickOutside = useCallback((event) => {
    const swalElements = document.getElementsByClassName(
      'swal2-container'
    ) as unknown as HTMLElement[];
    const btnElements = document.getElementsByClassName(
      'btn-option'
    ) as unknown as HTMLElement[];
    const elements = document.getElementsByClassName(
      'options'
    ) as unknown as HTMLElement[];
    let close = true;
    for (const element of elements) {
      for (const btnElement of btnElements) {
        if (
          btnElement.contains(event.target) ||
          element.contains(event.target)
        ) {
          close = false;
        }

        if (swalElements.length > 0) {
          for (const swalElement of swalElements) {
            if (swalElement.contains(event.target)) {
              close = false;
            }
          }
        }
      }
    }

    if (close) {
      setProjectSelected({} as IProject);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickDots = useCallback((project: IProject) => {
    setProjectSelected((state) =>
      state.id === project.id ? ({} as IProject) : project
    );
  }, []);

  const handleClickDeleteProject = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse projeto?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`projects/${projectSelected.project_id}`);

          loadProjects(page);
          setProjectSelected({} as IProject);

          Toast.fire({
            icon: 'success',
            title: 'Projeto deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [loadProjects, projectSelected.project_id, page]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Título',
        selector: 'title',
      },
      {
        name: 'Maternal',
        selector: 'nursery',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IProject) => (
          <div className="options-group position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent btn-option"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options className="options" active={projectSelected.id === row.id}>
              <Link to={`${process.env.PUBLIC_URL}/projetos/${row.project_id}`}>
                Editar projeto
              </Link>
              <button type="button" onClick={handleClickDeleteProject}>
                Deletar projeto
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [handleClickDeleteProject, handleClickDots, projectSelected.id]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadProjects(1, value);
    },
    [loadProjects]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h1 className="text-secondary">Projetos</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <Link
                  to={`${process.env.PUBLIC_URL}/projetos/novo`}
                  className="btn btn-primary rounded-pill py-2 px-4 d-flex aling-items-center ms-3"
                >
                  <FiPlus size={22} color="#fff" className="me-2" /> Novo
                </Link>
              </div>
            </div>
            <Table
              columns={columns}
              data={projects}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-projects mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default Projects;
