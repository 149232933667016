import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { darken } from 'polished';

interface IContainer {
  detailColor: string;
  tagColor: string;
  btnColor: string;
}

export const Container = styled.div<IContainer>`
  .box-process {
    border-radius: 32px;
    overflow: hidden;
    background: ${(props) => props.detailColor};

    .infos div:nth-child(2) {
      height: 128px;
    }

    .nursery-name {
      p {
        background: #fff;
        border-radius: 100px;
        color: #202020;

        + p {
          margin-top: 10px;
        }
      }
    }

    .tag {
      background: ${(props) => props.tagColor};
    }

    .see-process {
      background: ${(props) => props.btnColor};

      > div {
        background: ${(props) => props.detailColor};
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .quotation-box {
    border-radius: 13px;
    border: 1px solid #d7d7d7;

    .detail {
      width: 5px;
      height: 75px;
      border-radius: 16px;
      background: ${(props) => props.detailColor};
    }

    .quotation-data {
      .title {
        width: 100%;
      }

      .tag-box {
        .tag {
          background: ${(props) => props.tagColor};
          border-radius: 100px;
        }
      }

      .nursery-name {
        .tag {
          color: ${(props) => darken(0.4, props.tagColor)} !important;
          border: 1px solid ${(props) => darken(0.4, props.tagColor)};
          background-color: transparent;
          white-space: nowrap;
          text-align: center;
          + .tag {
            margin-top: 10px;
          }
        }
      }

      .btn-see-more {
        border-radius: 40px;
        background: ${(props) => props.btnColor};

        > div {
          background: transparent;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    + .quotation-box {
      margin-top: 13px;
    }
  }

  @media screen and (min-width: 992px) {
    .quotation-box {
      .detail {
        width: 8px;
      }

      .quotation-data {
        width: 100%;

        .title {
          width: calc(100% - 600px);
        }

        .tag-box {
          width: 175px;
        }

        .btn-see-more {
          > div {
            background: ${(props) => props.detailColor};
          }
        }
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;

      .btn {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }

      .btn-refuse {
        background-color: #ff1a50;

        :hover {
          background-color: ${darken(0.05, '#FF1A50')};
        }
      }

      .btn-accept {
        background-color: #00c968;

        :hover {
          background-color: ${darken(0.05, '#00C968')};
        }
      }

      .refused {
        background-color: #ff1a50;
        color: #fff;
      }
    }

    .input-file {
      position: relative;

      p {
        color: #777;
      }

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }

    .input-checkbox {
      justify-content: flex-start;
      flex-direction: column;

      label + label {
        margin-top: 10px;
        margin-left: 0;
      }
    }

    .btn-add-input {
      color: #777;
    }
  }
`;
