import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';

import { WhiteSpace, Container, Menu } from './styles';
import Icons from '../Icons';

import logo from '~/assets/logos/logo.svg';
import { useAuth } from '~/hooks/Auth';

const SideNavbar: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';
  }, [active]);

  useEffect(() => {
    if (!user.type) {
      signOut();
    }
  }, [signOut, user]);

  return (
    <>
      <WhiteSpace className="d-block d-lg-none" />
      <Container className="px-lg-4 py-lg-5 d-flex flex-column justify-content-between">
        <div className="mb-lg-5 p-4 p-lg-0 d-flex align-items-center justify-content-between">
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="d-flex align-items-center justify-content-center logo"
          >
            <img src={logo} alt="logo" className="me-2" />
            <span className="text-secondary text-start d-block small h5-lg mb-0 fw-bold">
              INSTITUTO <br />
              <span className="d-block h4 h2-lg mb-0 fw-bold">BARUERI</span>
            </span>
          </Link>
          <button
            type="button"
            className={`bg-transparent d-lg-none border-0 mx-3 btn-menu ${
              active ? 'active' : ''
            }`}
            onClick={() => setActive(!active)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <Menu active={active} className="p-3 p-lg-0">
          <div>
            {user.type?.types_menus.map((userTypeMenu) => (
              <NavLink
                key={userTypeMenu.menu.id}
                to={`${process.env.PUBLIC_URL}/${userTypeMenu.menu.link}`}
                className="d-flex align-items-center p-3 mb-4 item-menu"
                activeClassName="active"
              >
                <Icons title={userTypeMenu.menu.icon} />{' '}
                <span className="d-block ms-3">{userTypeMenu.menu.title}</span>
              </NavLink>
            ))}
            <button
              type="button"
              className="btn text-start d-flex align-items-center p-3 item-menu w-100"
              onClick={() => signOut()}
            >
              <TbLogout size={20} className="me-3" /> <span>Sair</span>
            </button>
          </div>
        </Menu>
      </Container>
    </>
  );
};

export default SideNavbar;
