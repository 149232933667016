import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  CompositeDecorator,
  ContentBlock,
  genKey,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { Container } from './styles';
import Textarea from '../Textarea';

export interface IFile {
  file: File;
  file_url: string;
}

interface IInputEditor {
  name: string;
  onChange?(value: string): void;
  value?: string;
  onChangeFiles?(files: IFile[]): void;
}

const InputEditor: React.FC<IInputEditor> = ({
  name,
  onChange,
  value: defaultValue,
  onChangeFiles,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const data = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ).replaceAll('<p></p>', '');
    setValue(data);
    if (onChange) {
      onChange(data);
    }
  }, [editorState, onChange]);

  useEffect(() => {
    if (defaultValue) {
      const data = convertFromHTML(
        defaultValue.replace(/<img(.*?)>/g, '<figure><img$1></figure>')
      );

      let { contentBlocks } = data;

      if (data.contentBlocks[0].getText() === '📷') {
        const emptyBlock = new ContentBlock({
          key: genKey(),
          text: '',
          type: 'unstyled',
        });
        contentBlocks = [emptyBlock, ...contentBlocks];
      }
      if (
        data.contentBlocks[data.contentBlocks.length - 1].getText() === '📷'
      ) {
        const emptyBlock = new ContentBlock({
          key: genKey(),
          text: '',
          type: 'unstyled',
        });
        contentBlocks = [...contentBlocks, emptyBlock];
      }

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, data.entityMap)
        )
      );
    }
  }, [defaultValue]);

  const uploadImageCallBack = useCallback(
    async (file) => {
      const link = URL.createObjectURL(file);

      if (onChangeFiles) {
        onChangeFiles([
          ...files,
          {
            file,
            file_url: link,
          },
        ]);
      }

      setFiles([
        ...files,
        {
          file,
          file_url: link,
        },
      ]);

      return { data: { link } };
    },
    [files, onChangeFiles]
  );

  return (
    <Container>
      <Editor
        placeholder={!focused ? 'Digite aqui...' : ''}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
          },
        }}
        editorClassName="editor"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Textarea
        name={name}
        className="d-none"
        value={value.replace('<p></p>', '').trim()}
      />
    </Container>
  );
};

export default InputEditor;
