import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

import transparency from '~/assets/defaults/bg-transparency.png';

interface IBanner {
  color: string;
}

interface IBtnTitle {
  opened: boolean;
}

interface IDocuments {
  height: number;
  opened: boolean;
}

export const Container = styled.div`
  .btn-add {
    background: #f9f9f9;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    height: 26vw;
    min-height: 205px;
    max-height: 225px;
    transition-duration: 0.3s;

    svg {
      color: #a3a3a3;
      transition-duration: 0.3s;
    }

    span {
      color: #a3a3a3;
      transition-duration: 0.3s;
    }

    :hover {
      background: ${darken(0.03, '#f9f9f9')};

      svg {
        color: #777777;
      }

      span {
        color: #777777;
      }
    }
  }
`;

export const BtnTitle = styled.button<IBtnTitle>`
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  transition-duration: 0.3s;
  border-radius: ${(props) => (props.opened ? '10px 10px 0 0' : '10px')};

  svg {
    transition-duration: 0.3s;
    transform: rotateZ(${(props) => (props.opened ? '0' : '90deg')});
  }
`;

export const Documents = styled.div<IDocuments>`
  margin-top: -1px;
  overflow: hidden;
  height: ${(props) => (props.opened ? props.height : 0)}px;
  transition-duration: 0.3s;

  .all {
    border: 1px solid #e2e2e2;
    border-radius: 0 0 10px 10px;
  }
`;

export const BannerTrasparency = styled.div<IBanner>`
  background: url(${transparency}),
    linear-gradient(
      180deg,
      ${(props) => props.color} 0%,
      ${(props) => props.color} 100%
    );
  /* background: ${(props) => props.color}; */
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 26vw;
  min-height: 205px;
  max-height: 225px;
  position: relative;
  overflow: hidden;

  .options {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;

    a,
    button {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      transition-duration: 0.3s;

      :nth-child(1) {
        span {
          color: #c9e2ff;
        }
      }

      :nth-child(2) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;

        span {
          color: #fff;
        }
      }

      :nth-child(3) {
        span {
          color: #ff1a50;
        }
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  :hover {
    .options {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;
    }

    .input-file {
      position: relative;
      height: unset;
      min-height: 46px;
      padding: 10px 0;

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }
  }
`;
