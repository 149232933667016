import React from 'react';

import { Container, Spinner } from './styles';

interface ILoading {
  active: boolean;
  text?: string;
  color?: string;
}

const Loading: React.FC<ILoading> = ({ active, text, color = '#F15A29' }) => {
  return (
    <Container active={active}>
      <Spinner color={color}>
        <div className="spinner" />
      </Spinner>
      {text && <p>{text}</p>}
    </Container>
  );
};

export default Loading;
