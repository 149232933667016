/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import { MdClose } from 'react-icons/md';
import { BsCheckLg, BsTrashFill, BsXLg } from 'react-icons/bs';
import { VscInfo } from 'react-icons/vsc';
import { IoDocumentText } from 'react-icons/io5';
import { TiArrowRight } from 'react-icons/ti';
import { useParams } from 'react-router-dom';

import Toast from '~/utils/toast';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';

import { IArchive, IProcess, IProcessDataResponse } from '..';
import Loading from '~/components/Loading';

interface IProcessNursery {
  id: number;
  nursery: {
    name: string;
  };
}

interface INursery {
  id: number;
  name: string;
}

interface IFormData {
  title: string;
  expiryDate: string;
}

interface IShowProcess {
  type: 'recent' | 'old';
  process: IProcess;
  className?: string;
  onAccept?(): void;
  onSucceededUpdateProcess?(): void;
  onSucceededDeleteProcess?(): void;
  onFinalizeProcess?(): void;
}

interface IParams {
  type: string;
}

const ShowProcess: React.FC<IShowProcess> = ({
  type,
  process,
  className,
  onAccept,
  onSucceededUpdateProcess,
  onSucceededDeleteProcess,
  onFinalizeProcess,
}) => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState<File[]>([]);
  const [documentsInput, setDocumentsInput] = useState<string[]>([
    'document-1',
  ]);
  const [errorDocuments, setErrorDocuments] = useState('');
  const [processSelected, setProcessSelected] = useState({} as IProcess);
  const [showUpdate, setShowUpdate] = useState(false);
  const [processDocuments, setProcessDocuments] = useState<IArchive[]>([]);
  const [oldProcessDocuments, setOldProcessDocuments] = useState<IArchive[]>(
    []
  );
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [recents, setRecents] = useState<IProcess[]>([]);
  const [colors, setColors] = useState({
    detail: '#a5a5a5',
    tag: '#eaeaea',
    btn: '#5b5b5b',
  });
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [nurseriesError, setNurseriesError] = useState('');
  const [nurseriesOption, setNurseriesOption] = useState<IOption[]>([]);
  const [oldProcessesNurseries, setOldProcessesNurseries] = useState<
    IProcessNursery[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          value: nursery.id,
          label: nursery.name,
          selected: !!process.nurseries.find((nurseryData) =>
            nursery.name.includes(nurseryData)
          ),
        }));
        setNurseries(data);
      });
  }, [process.nurseries]);

  useEffect(() => {
    if (process.status === 'Em aberto') {
      setColors({
        detail: '#00a189',
        tag: '#c5f0ea',
        btn: '#066456',
      });
    }
    if (process.status === 'Em andamento') {
      setColors({
        detail: '#4589da',
        tag: '#c9e2ff',
        btn: '#0d4485',
      });
    }
    if (process.status === 'Finalizado') {
      setColors({
        detail: '#e6883b',
        tag: '#f0d7c5',
        btn: '#a74b00',
      });
    }
  }, [process.status]);

  const handleClose = useCallback(() => {
    setNurseriesOption([]);
    setDocuments([]);
    setShow(false);
  }, []);

  const handleCloseUpdate = useCallback(() => {
    setNurseriesOption([]);
    setDocuments([]);
    setShowUpdate(false);
  }, []);

  const handleClick = useCallback(
    async (process_id) => {
      const response = await api.get<IProcessDataResponse>(
        `processes/${process_id}`,
        {
          params: {
            isAdmin: true,
          },
        }
      );
      const documentsProcess = response.data.archives.filter(
        (archive) => !archive.quotation
      );
      const newDocumentsInput = documentsProcess.map(
        (_, index) => `document-${index + 1}`
      );

      if (process.status !== 'Em aberto') {
        const archives = response.data.archives.filter(
          (archive) => !archive.quotation || archive.quotation?.accepted_at
        );
        setProcessSelected({
          ...response.data,
          archives,
        } as unknown as IProcess);
      } else {
        setProcessSelected(response.data as unknown as IProcess);
      }

      setOldProcessDocuments(documentsProcess);
      setProcessDocuments(documentsProcess);
      setOldProcessesNurseries(response.data.processes_nurseries);
      setNurseriesOption(nurseries);
      setDocumentsInput(newDocumentsInput);
      setShow(true);
    },
    [nurseries, process.status]
  );

  const handleChangeDocument = useCallback((files: File[], index) => {
    setDocuments((state) => {
      const newDocuments = state.slice();
      if (newDocuments[index]) {
        newDocuments[index] = files[0];

        return newDocuments;
      }

      return [...newDocuments, files[0]];
    });
  }, []);

  const handleClickAddDocument = useCallback(() => {
    setDocumentsInput((state) => [...state, `documento-${state.length + 1}`]);
  }, []);

  const handleClickRemoveDocument = useCallback(
    (index) => {
      if (documentsInput.length > 1) {
        setDocumentsInput((state) => state.filter((_, idx) => idx !== index));
      }

      setDocuments((state) => state.filter((_, idx) => idx !== index));
    },
    [documentsInput.length]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setErrorDocuments('');
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          nurseries: Yup.string().when('$nurseriesCheck', {
            is: (nurseriesCheck: boolean) => nurseriesCheck,
            then: Yup.string().required('Escolha pelo menos um maternal'),
            otherwise: Yup.string(),
          }),
          expiryDate: Yup.string().required(
            'A data de vencimento é obrigatória'
          ),
          documents: Yup.string().when('$documentsCheck', {
            is: (documentsCheck: boolean) => documentsCheck,
            then: Yup.string().required('Ao menos um documento é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        const nurseriesSelected = nurseriesOption.filter(
          (nursery) => nursery.selected
        );

        await schema.validate(data, {
          abortEarly: false,
          context: {
            documentsCheck:
              documents.length === 0 && processDocuments.length === 0,
            nurseriesCheck: nurseriesSelected.length === 0,
          },
        });

        const date = parseISO(data.expiryDate).setHours(23, 59, 59, 99);

        const formData = {
          title: data.title,
          status: 'Em aberto',
          expiry_date: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
          type: params.type === 'fornecedores' ? 'supplier' : 'provider',
        };

        const response = await api.put(
          `processes/${processSelected.id}`,
          formData
        );

        const deletedProcessesNurseries = oldProcessesNurseries.filter(
          (oldProcessNursery) =>
            !nurseries.find(
              (nursery) =>
                nursery.label === oldProcessNursery.nursery.name &&
                nursery.selected
            )
        );

        if (deletedProcessesNurseries.length > 0) {
          await new Promise<void>((resolve) => {
            deletedProcessesNurseries.forEach(async (processNursery, index) => {
              await api.delete(`processes-nurseries/${processNursery.id}`);

              if (deletedProcessesNurseries.length === index + 1) {
                resolve();
              }
            });
          });
        }

        const newProcessesNurseries = nurseries
          .filter(
            (nursery) =>
              !oldProcessesNurseries.find(
                (oldProcessNursery) =>
                  nursery.label === oldProcessNursery.nursery.name
              )
          )
          .filter((nursery) => nursery.selected);

        if (newProcessesNurseries.length > 0) {
          await new Promise<void>((resolve) => {
            newProcessesNurseries.forEach(async (nursery, index) => {
              await api.post('processes-nurseries', {
                process_id: response.data.id,
                nursery_id: nursery.value,
              });

              if (newProcessesNurseries.length === index + 1) {
                resolve();
              }
            });
          });
        }

        const deletedDocuments = oldProcessDocuments.filter(
          (oldDoc) =>
            !processDocuments.find((procDoc) => oldDoc.id === procDoc.id)
        );

        if (deletedDocuments.length > 0) {
          await new Promise<void>((resolve) => {
            deletedDocuments.forEach(async (document, index) => {
              await api.delete(`archives/${document.id}`);

              if (deletedDocuments.length === index + 1) {
                resolve();
              }
            });
          });
        }

        if (documents.length > 0) {
          await new Promise<void>((resolve) => {
            documents.forEach(async (document, index) => {
              const documentFormData = new FormData();
              documentFormData.append('document', document);
              documentFormData.append('process_id', response.data.id);
              documentFormData.append('title', 'Documento');
              await api.post('archives', documentFormData);

              if (documents.length === index + 1) {
                resolve();
              }
            });
          });
        }

        const responseProcess = await api.get<IProcess>(
          `processes/${processSelected.id}`,
          {
            params: {
              isAdmin: true,
            },
          }
        );
        const documentsProcess = responseProcess.data.archives.filter(
          (archive) => !archive.quotation
        );
        const newDocumentsInput = documentsProcess.map(
          (_, index) => `document-${index + 1}`
        );

        const newProcesses = processes.slice();
        const processIndex = newProcesses.findIndex(
          (processData) => processData.id === processSelected.id
        );

        if (processIndex >= 0) {
          newProcesses[processIndex] = responseProcess.data;
        } else {
          const newRecents = recents.slice();
          const recentsIndex = newRecents.findIndex(
            (processData) => processData.id === processSelected.id
          );

          if (recentsIndex >= 0) {
            newRecents[recentsIndex] = responseProcess.data;
          }
          setRecents(newRecents);
        }

        setProcesses(newProcesses);
        setOldProcessDocuments(documentsProcess);
        setProcessDocuments(documentsProcess);
        setDocumentsInput(newDocumentsInput);
        setProcessSelected(responseProcess.data);
        handleCloseUpdate();

        Toast.fire({
          icon: 'success',
          title: 'Processo editado!',
        });

        handleClick(response.data.id);

        if (onSucceededUpdateProcess) {
          onSucceededUpdateProcess();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (errors.documents) {
            setErrorDocuments(errors.documents);
          }

          if (errors.nurseries) {
            setNurseriesError(errors.nurseries);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      documents,
      handleClick,
      handleCloseUpdate,
      nurseries,
      nurseriesOption,
      oldProcessDocuments,
      oldProcessesNurseries,
      onSucceededUpdateProcess,
      params.type,
      processDocuments,
      processSelected.id,
      processes,
      recents,
    ]
  );

  const handleClickRefuse = useCallback(
    async (quotation) => {
      const { isConfirmed, value: reason } = await Swal.fire({
        input: 'textarea',
        title: 'Qual o motivo da recusa?',
        inputPlaceholder: 'Digite aqui...',
        showCancelButton: true,
        confirmButtonText: 'Recusar',
        confirmButtonColor: '#F15A29',
        cancelButtonText: 'Fechar',
        cancelButtonColor: '#a6a6a6',
      });

      if (isConfirmed) {
        const formData = {
          refused_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          reason,
        };

        await api.patch(`quotations/${quotation.id}/respond`, formData);

        const response = await api.get(`processes/${processSelected.id}`, {
          params: {
            isAdmin: true,
          },
        });
        setProcessSelected(response.data);
      }
    },
    [processSelected.id]
  );

  const handleClickAccept = useCallback(
    async (quotation) => {
      const { isConfirmed } = await Swal.fire({
        title: 'Aprovar orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f15a29',
        cancelButtonText: 'Não',
        cancelButtonColor: '#a6a6a6',
      });

      if (isConfirmed) {
        setLoading(true);
        try {
          const formData = {
            accepted_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          };

          await api.patch(`quotations/${quotation.id}/respond`, formData);

          const processFormData = {
            title: processSelected.title,
            status: 'Em andamento',
            expiry_date: processSelected.expiry_date,
            type: params.type === 'fornecedores' ? 'supplier' : 'provider',
          };

          await api.put(`processes/${processSelected.id}`, processFormData);

          if (onAccept) {
            onAccept();
          }
          handleClose();
          Toast.fire({
            icon: 'success',
            title: 'Cotação aprovada e em andamento!',
          });
        } catch (error) {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        } finally {
          setLoading(false);
        }
      }
    },
    [
      handleClose,
      onAccept,
      params.type,
      processSelected.expiry_date,
      processSelected.id,
      processSelected.title,
    ]
  );

  const handleClickDelete = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse processo?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`processes/${process.id}`);

          Toast.fire({
            icon: 'success',
            title: 'Processo deletado!',
          });

          if (onSucceededDeleteProcess) {
            onSucceededDeleteProcess();
          }

          handleClose();
          handleCloseUpdate();
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [handleClose, handleCloseUpdate, onSucceededDeleteProcess, process.id]);

  const handleChangeNurseries = useCallback((nurseriesOptions) => {
    setNurseriesOption(nurseriesOptions);
  }, []);

  const handleClickFinalize = useCallback(async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Finalizar processo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonText: 'Não',
      cancelButtonColor: '#a6a6a6',
    });

    if (isConfirmed) {
      const processFormData = {
        title: processSelected.title,
        status: 'Finalizado',
        expiry_date: processSelected.expiry_date,
        type: params.type === 'fornecedores' ? 'supplier' : 'provider',
      };

      await api.put(`processes/${processSelected.id}`, processFormData);

      if (onFinalizeProcess) {
        onFinalizeProcess();
      }
      handleClose();
      Toast.fire({
        icon: 'success',
        title: 'Processo finalizado com sucesso!',
      });
    }
  }, [
    handleClose,
    onFinalizeProcess,
    params.type,
    processSelected.expiry_date,
    processSelected.id,
    processSelected.title,
  ]);

  return (
    <Container
      detailColor={colors.detail}
      tagColor={colors.tag}
      btnColor={colors.btn}
      className={className}
    >
      {type === 'recent' && (
        <button
          key={process.id}
          type="button"
          className="w-100 h-100 border-0 bg-transparent text-start"
          onClick={() => handleClick(process.id)}
        >
          <div className="box-process d-flex flex-column justify-content-between h-100">
            <div className="infos d-flex flex-column justify-content-between h-100">
              <div className="d-flex justify-content-lg-between align-items-start px-4 pt-4">
                <div className="d-flex flex-column nursery-name align-self-center">
                  {process.nurseries.length > 0 ? (
                    <>
                      {process.nurseries.map((nursery) => (
                        <p key={nursery} className="h6 px-3 py-1 mb-0">
                          {nursery}
                        </p>
                      ))}
                    </>
                  ) : (
                    <p className="h6 px-3 py-1 mb-0">Não informado</p>
                  )}
                </div>
                <p className="fw-medium text-secondary tag rounded-pill px-3 py-1  mb-0">
                  Em aberto
                </p>
              </div>
              <div className="pt-lg-5 px-4 pb-4 h-auto">
                <p className="text-white h5 fw-semibold">{process.title}</p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
              <p className="text-white mb-0 me-2">Ver mais</p>
              <div>
                <TiArrowRight size={20} color="#fff" />
              </div>
            </div>
          </div>
        </button>
      )}
      {type === 'old' && (
        <div
          key={process.id}
          className="d-flex align-items-center quotation-box py-3 px-4"
        >
          <div className="detail me-3" />
          <div className="d-flex flex-wrap justify-content-between align-items-center quotation-data">
            <p className="h6 h5-lg mb-0 text-secondary fw-normal title mb-3 mb-lg-0 py-2">
              {process.title}
            </p>
            <div className="d-flex flex-column tag-box nursery-name mx-lg-3">
              {process.nurseries.length > 0 ? (
                <>
                  {process.nurseries.map((nursery) => (
                    <p
                      key={nursery}
                      className="mb-0 px-3 px-lg-4 py-1 py-lg-2 mb-0 text-secondary tag open"
                    >
                      {nursery}
                    </p>
                  ))}
                </>
              ) : (
                <p className="mb-0 px-3 px-lg-4 py-1 py-lg-2 mb-0 text-secondary tag open">
                  Não informado
                </p>
              )}
            </div>
            <div className="d-flex justify-content-lg-end tag-box mx-lg-3">
              <p className="mb-0 px-3 px-lg-4 py-1 py-lg-2 mb-0 text-secondary tag open">
                {process.status}
              </p>
            </div>
            <button
              type="button"
              className="btn rounded-pill btn-see-more p-0 py-lg-2 px-lg-4 d-flex justify-content-center align-items-center"
              onClick={() => handleClick(process.id)}
            >
              <p className="mb-0 me-lg-2 text-white fw-semibold d-none d-lg-block">
                Ver mais
              </p>
              <div>
                <TiArrowRight size={20} color="#fff" />
              </div>
            </button>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose} size="lg" enforceFocus={false}>
        {Object.keys(processSelected).length > 0 && (
          <>
            <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
              <Modal.Title className="text-secondary fw-semibold mb-0">
                {processSelected.title}
              </Modal.Title>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={handleClose}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-5 my-3">
              {processSelected.archives.map((archive) => (
                <div className="btn btn-archive d-flex align-items-center justify-content-between p-3 mb-3">
                  <a
                    href={archive.archive_url}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center justify-content-between w-100"
                  >
                    <span>
                      <IoDocumentText
                        size={24}
                        color="#4A4747"
                        className="me-2"
                      />{' '}
                      {archive.title}{' '}
                      {archive.title.toLowerCase().includes('orçamento') &&
                        format(parseISO(archive.created_at), 'dd/MM/yyyy')}
                    </span>
                    {archive.quotation && archive.quotation.refused_at && (
                      <p className="mb-0 refused px-3 py-2 rounded-pill d-flex align-items-center">
                        <BsXLg size={20} color="#fff" className="me-2" />
                        Recusada
                      </p>
                    )}
                  </a>
                  {archive.quotation &&
                    !archive.quotation.accepted_at &&
                    !archive.quotation.refused_at && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-refuse rounded-circle me-2"
                          onClick={() => handleClickRefuse(archive.quotation)}
                        >
                          <BsXLg size={20} color="#fff" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-accept rounded-circle"
                          onClick={() => handleClickAccept(archive.quotation)}
                        >
                          <BsCheckLg size={24} color="#fff" />
                        </button>
                      </div>
                    )}
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 px-5 mb-2 justify-content-between">
              <button
                type="button"
                className="btn btn-danger rounded-pill py-2 px-4"
                onClick={handleClickDelete}
              >
                Deletar processo
              </button>
              {process.status === 'Em aberto' && (
                <button
                  type="button"
                  className="btn btn-primary rounded-pill py-2 px-4"
                  onClick={() => setShowUpdate(true)}
                >
                  Editar processo
                </button>
              )}
              {process.status === 'Em andamento' && (
                <button
                  type="button"
                  className="btn btn-primary rounded-pill py-2 px-4"
                  onClick={handleClickFinalize}
                >
                  Finalizar processo
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal show={showUpdate} onHide={handleCloseUpdate} size="lg">
        {Object.keys(processSelected).length > 0 && (
          <Form
            ref={formRef}
            initialData={{
              title: processSelected.title,
              expiryDate: format(
                parseISO(processSelected.expiry_date),
                'yyyy-MM-dd'
              ),
            }}
            onSubmit={handleSubmit}
          >
            <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
              <Modal.Title className="text-secondary fw-semibold mb-0">
                Editar processo
              </Modal.Title>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={handleCloseUpdate}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-5">
              <label className="d-block mb-4">
                <span className="d-block mb-2 text-secondary fw-medium">
                  Título
                </span>
                <Input name="title" className="input mt-2" />
              </label>
              <label className="d-block mb-4">
                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                  Maternal
                </span>
                <InputCheckbox
                  type="checkbox"
                  name="nurseries"
                  options={nurseries}
                  className="input-checkbox"
                  onChange={handleChangeNurseries}
                  error={nurseriesError}
                />
              </label>
              <label className="d-block mb-4">
                <span className="d-block mb-2 text-secondary fw-medium">
                  Data de vencimento
                </span>
                <Input type="date" name="expiryDate" className="input mt-2" />
              </label>
              <label className="d-block mt-4">
                <span className="d-block mb-2 text-secondary fw-medium">
                  Documentos
                </span>
              </label>
              {documentsInput.map((documentInput, index) => (
                <div className="d-flex align-items-center mt-2">
                  <InputFile
                    name={`${documentInput}-${index}`}
                    className="input-file"
                    onChange={(files) => handleChangeDocument(files, index)}
                    value={
                      (processDocuments[index] &&
                        processDocuments[index].name) ||
                      undefined
                    }
                  />
                  <button
                    type="button"
                    className="btn"
                    onClick={() => handleClickRemoveDocument(index)}
                  >
                    <BsTrashFill size={24} color="#FF1A50" />
                  </button>
                </div>
              ))}
              {errorDocuments && (
                <div className="w-100 d-flex align-items-center mt-1 error-box">
                  <VscInfo size={24} color="#FF1A50" className="me-1" />
                  <span className="small text-danger error mb-0">
                    {errorDocuments}
                  </span>
                </div>
              )}
              <button
                type="button"
                onClick={handleClickAddDocument}
                className="btn btn-add-input d-flex align-items-center mt-2"
              >
                <FiPlus size={22} color="#777" className="me-2" /> Adicionar
                mais documento
              </button>
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 px-5 mb-2 justify-content-between">
              <button
                type="button"
                className="btn btn-danger rounded-pill py-2 px-4"
                onClick={handleClickDelete}
              >
                Deletar processo
              </button>
              <button
                type="submit"
                className="btn btn-primary rounded-pill py-2 px-4"
              >
                Enviar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      <Loading active={loading} text="Aceitando orçamento..." />
    </Container>
  );
};

export default ShowProcess;
