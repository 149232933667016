/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import ChangePassword from '~/pages/ChangePassword';
import SignUp from '~/pages/SignUp';
import Dashboard from '~/pages/Dashboard';
import Processes from '~/pages/Processes';
import OpenProcesses from '~/pages/Processes/Open';
import ProcessesInProgress from '~/pages/Processes/InProgress';
import FinalizedProcesses from '~/pages/Processes/Finalized';
import Suppliers from '~/pages/Suppliers';
import SuppliersRegister from '~/pages/Suppliers/Register';
import SuppliersProfile from '~/pages/Suppliers/Profile';
import Nurseries from '~/pages/Nurseries';
import NurseriesRegister from '~/pages/Nurseries/Register';
import NurseriesUpdate from '~/pages/Nurseries/Update';
import Projects from '~/pages/Projects';
import ProjectsRegister from '~/pages/Projects/Register';
import ProjectsUpdate from '~/pages/Projects/Update';
import Galleries from '~/pages/Galleries';
import GalleryRegister from '~/pages/Galleries/Register';
import GalleryUpdate from '~/pages/Galleries/Update';
import TransparencyPortal from '~/pages/TransparencyPortal';
import Menus from '~/pages/Menus';
import Notices from '~/pages/Notices';
import NoticesRegister from '~/pages/Notices/Register';
import NoticesUpdate from '~/pages/Notices/Update';
import Profile from '~/pages/Profile';

interface IRoutes {
  setPathname: React.Dispatch<React.SetStateAction<string>>;
}

const routes: React.FC<IRoutes> = ({ setPathname }) => {
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname, setPathname]);

  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-minha-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/recuperar-senha/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastro`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastro/:step`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        exact
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/processos/:type`}
        exact
        isPrivate
        component={Processes}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/processos/:type/abertos`}
        exact
        isPrivate
        component={OpenProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/processos/:type/andamento`}
        exact
        isPrivate
        component={ProcessesInProgress}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/processos/:type/finalizados`}
        exact
        isPrivate
        component={FinalizedProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedores`}
        exact
        isPrivate
        component={Suppliers}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedores/novo/:step`}
        exact
        isPrivate
        component={SuppliersRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedores/:supplierId/:step`}
        exact
        isPrivate
        component={SuppliersProfile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/prestadores`}
        exact
        isPrivate
        component={Suppliers}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/prestadores/novo/:step`}
        exact
        isPrivate
        component={SuppliersRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/prestadores/:supplierId/:step`}
        exact
        isPrivate
        component={SuppliersProfile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais`}
        exact
        isPrivate
        component={Nurseries}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/novo`}
        exact
        isPrivate
        component={NurseriesRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:nurseryId`}
        exact
        isPrivate
        component={NurseriesUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/projetos`}
        exact
        isPrivate
        component={Projects}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/projetos/novo`}
        exact
        isPrivate
        component={ProjectsRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/projetos/:projectId`}
        exact
        isPrivate
        component={ProjectsUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/galerias`}
        exact
        isPrivate
        component={Galleries}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/galerias/nova`}
        exact
        isPrivate
        component={GalleryRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/galerias/:galleryId`}
        exact
        isPrivate
        component={GalleryUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portal-da-transparencia`}
        exact
        isPrivate
        component={TransparencyPortal}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cardapios`}
        exact
        isPrivate
        component={Menus}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias`}
        exact
        isPrivate
        component={Notices}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/nova`}
        exact
        isPrivate
        component={NoticesRegister}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/:noticeId`}
        exact
        isPrivate
        component={NoticesUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil`}
        exact
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil/:step`}
        exact
        isPrivate
        component={Profile}
      />
    </Switch>
  );
};

export default routes;
