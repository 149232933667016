import styled from 'styled-components';

interface IAvatar {
  src: string;
}

interface ITabsGroup {
  opened: boolean;
}

interface ILinkFile {
  disabled?: boolean;
}

export const Container = styled.div`
  min-height: calc(100vh - 70px);

  .box {
    border-radius: 16px;
    border: 1px solid #e3dede;
    background: #fff;

    .box-avatar {
      border-radius: 9px;
      background: #f9f9f9;
      cursor: pointer;
    }

    .links {
      a {
        position: relative;
        transition-duration: 0.3s;

        :after {
          content: '';
          position: absolute;
          border-radius: 18px;
          background: transparent;
          width: 35px;
          height: 4px;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          transition-duration: 0.3s;
        }
      }

      a:hover,
      .active {
        color: #db562b !important;

        :after {
          background: #db562b;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .box-avatar {
      border-radius: 9px;
      cursor: pointer;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-image: url(${(props) => props.src});
  background-color: #224b8f;
  background-position: center;
  background-size: cover;
  position: relative;

  .icon {
    position: absolute;
    background-color: #e6883b;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: 0;
    bottom: 0;
  }
`;

export const Tabs = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;

  @media screen and (min-width: 1200px) {
    background-color: transparent;
  }
`;

export const TabsButton = styled.button`
  color: #db562b;
`;

export const TabsGroup = styled.div<ITabsGroup>`
  transition-duration: 0.3s;
  height: ${(props) => (props.opened ? '200px' : '0')};
  overflow: hidden;
  padding-top: ${(props) => (props.opened ? '20px' : '0')};
  margin-top: ${(props) => (props.opened ? '20px' : '0')};
  border-top: ${(props) => (props.opened ? '1px solid #707070' : 'none')};

  a {
    position: relative;
    transition-duration: 0.3s;

    :after {
      content: '';
      position: absolute;
      border-radius: 18px;
      background: transparent;
      width: 35px;
      height: 4px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      transition-duration: 0.3s;
    }

    + a {
      margin-top: 10px;
    }
  }

  .active {
    color: #db562b !important;
  }

  @media screen and (min-width: 768px) {
    height: unset;
    overflow: unset;

    a {
      + a {
        margin-top: 0px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    a:hover,
    .active {
      color: #db562b !important;

      :after {
        background: #db562b;
      }
    }
  }
`;

export const LinkFile = styled.a<ILinkFile>`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;
