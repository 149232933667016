/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-duplicates */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';
import PtBr from 'date-fns/locale/pt-BR';
import {
  RiArrowDownSLine,
  RiDeleteBinFill,
  RiEyeLine,
  RiPencilLine,
} from 'react-icons/ri';
import { FiPlus } from 'react-icons/fi';

import { IoIosArrowDown } from 'react-icons/io';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, MenusGroup, Menu, BtnTitle, Modal } from './styles';
import Loading from '~/components/Loading';
import Search from '~/components/Search';
import { IOption } from '~/components/Select';
import InputFile from '~/components/InputFile';

import menusIcon from '~/assets/icons/menus.png';
import NoData from '~/components/NoData';

interface IMenuResponse {
  id: number;
  nursery_id?: number;
  month: number;
  year: number;
  archive: {
    id: number;
    archive_url: string;
  };
}

interface IMenu {
  id: number;
  nursery_id?: number;
  archive_id: number;
  title: string;
  color: string;
  url?: string;
  month: number;
  year: number;
  default?: boolean;
}

interface INurseryResponse {
  id: number;
  name: string;
  menus: IMenuResponse[];
}

interface INursery {
  id: number;
  name: string;
  menus: IMenu[];
  opened: boolean;
}

const Menus: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const [apiNurseries, setApiNurseries] = useState<INursery[]>([]);
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [loading, setLoading] = useState(false);
  const [nurseySelected, setNurserySelected] = useState({} as INursery);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newMenu, setNewMenu] = useState<File | undefined>(undefined);
  const [menusError, setMenusError] = useState('');
  const [option, setOption] = useState('default');
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const [opened, setOpened] = useState(false);
  const [defaultMenus, setDefaultMenus] = useState<IMenu[]>([]);
  const [apiMenus, setApiMenus] = useState<IMenu[]>([]);
  const [menuSelected, setMenuSelected] = useState({} as IMenu);

  const loadMenus = useCallback(async () => {
    const response = await api.get<IMenuResponse[]>(`menus`, {
      params: {
        default: true,
        year: yearSelected,
      },
    });

    const data = response.data.map<IMenu>((menu) => {
      const month = format(new Date(0, menu.month - 1), 'MMMM', {
        locale: PtBr,
      });

      const monthAux = `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`;

      let color = '';
      switch (monthAux) {
        case 'Janeiro':
          color = '#22B5B9';

          break;
        case 'Fevereiro':
          color = '#7E4AC8';

          break;
        case 'Março':
          color = '#01499D';

          break;
        case 'Abril':
          color = '#108CB2';

          break;
        case 'Maio':
          color = '#D7B400';

          break;
        case 'Junho':
          color = '#CE0F3C';

          break;
        case 'Julho':
          color = '#00A189';

          break;
        case 'Agosto':
          color = '#D79B00';

          break;
        case 'Setembro':
          color = '#E7C200';

          break;
        case 'Outubro':
          color = '#E63BA2';

          break;
        case 'Novembro':
          color = '#0075FF';

          break;
        case 'Dezembro':
          color = '#F30303';

          break;

        default:
          break;
      }
      return {
        id: menu.id,
        archive_id: menu.archive.id,
        title: `${monthAux}/${menu.year}`,
        color,
        url: menu.archive.archive_url,
        month: menu.month,
        year: menu.year,
      };
    });
    setApiMenus(data);
  }, [yearSelected]);

  const loadNurseries = useCallback(async (search = '') => {
    const response = await api.get<INurseryResponse[]>('nurseries', {
      params: {
        type: 'menus',
        search,
      },
    });

    const data = response.data.map<INursery>((nursery) => {
      const menus = nursery.menus.map<IMenu>((menu) => {
        const month = format(new Date(0, menu.month - 1), 'MMMM', {
          locale: PtBr,
        });

        const monthAux = `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`;

        let color = '';
        switch (monthAux) {
          case 'Janeiro':
            color = '#22B5B9';

            break;
          case 'Fevereiro':
            color = '#7E4AC8';

            break;
          case 'Março':
            color = '#01499D';

            break;
          case 'Abril':
            color = '#108CB2';

            break;
          case 'Maio':
            color = '#D7B400';

            break;
          case 'Junho':
            color = '#CE0F3C';

            break;
          case 'Julho':
            color = '#00A189';

            break;
          case 'Agosto':
            color = '#D79B00';

            break;
          case 'Setembro':
            color = '#E7C200';

            break;
          case 'Outubro':
            color = '#E63BA2';

            break;
          case 'Novembro':
            color = '#0075FF';

            break;
          case 'Dezembro':
            color = '#F30303';

            break;

          default:
            break;
        }

        return {
          id: menu.id,
          nursery_id: menu.nursery_id,
          archive_id: menu.archive?.id,
          title: `${monthAux}/${menu.year}`,
          color,
          url: menu.archive?.archive_url,
          month: menu.month,
          year: menu.year,
        };
      });

      return {
        id: nursery.id,
        name: nursery.name,
        menus,
        opened: true,
      };
    });

    setApiNurseries(data);
  }, []);

  useEffect(() => {
    const data = Array.from({ length: 12 }, (_, index) => {
      const month = format(new Date(0, index), 'MMMM', {
        locale: PtBr,
      });

      const monthAux = `${month.slice(0, 1).toUpperCase()}${month.slice(1)}`;

      let color = '';
      switch (monthAux) {
        case 'Janeiro':
          color = '#22B5B9';

          break;
        case 'Fevereiro':
          color = '#7E4AC8';

          break;
        case 'Março':
          color = '#01499D';

          break;
        case 'Abril':
          color = '#108CB2';

          break;
        case 'Maio':
          color = '#D7B400';

          break;
        case 'Junho':
          color = '#CE0F3C';

          break;
        case 'Julho':
          color = '#00A189';

          break;
        case 'Agosto':
          color = '#D79B00';

          break;
        case 'Setembro':
          color = '#E7C200';

          break;
        case 'Outubro':
          color = '#E63BA2';

          break;
        case 'Novembro':
          color = '#0075FF';

          break;
        case 'Dezembro':
          color = '#F30303';

          break;

        default:
          break;
      }
      return {
        id: index + 1,
        archive_id: 0,
        title: `${monthAux}/${yearSelected}`,
        color,
        url: '',
        month: index + 1,
        year: yearSelected,
      };
    });

    setDefaultMenus(data.reverse());
  }, [yearSelected]);

  useEffect(() => {
    setLoading(true);
    loadMenus().finally(async () => {
      if (option === 'single') {
        await loadNurseries();
      }

      setLoading(false);
    });
  }, [loadMenus, loadNurseries, option]);

  const menus = useMemo<IMenu[]>(() => {
    return defaultMenus.map((defaultMenu) => {
      const checkMenu = apiMenus.find(
        (apiMenu) => apiMenu.title === defaultMenu.title
      );
      return checkMenu || defaultMenu;
    });
  }, [apiMenus, defaultMenus]);

  useEffect(() => {
    const data = apiNurseries.map<INursery>((nursery) => {
      const newMenus = menus.map((menu) => {
        const checkMenu = nursery.menus.find(
          (nurseryMenu) => nurseryMenu.title === menu.title
        );
        return checkMenu || { ...menu, nursery_id: nursery.id, default: true };
      });

      return {
        ...nursery,
        menus: newMenus,
      };
    });
    setNurseries(data);
  }, [apiNurseries, menus]);

  const handleClickOption = useCallback(
    (optionData) => {
      if (optionData === 'single') {
        loadNurseries();
      }
      setOption(optionData);
    },
    [loadNurseries]
  );

  const handleClickYear = useCallback(() => {
    setOpened((state) => !state);
  }, []);

  const handleSelectYear = useCallback((year) => {
    setYearSelected(year);
  }, []);

  const years = useMemo<IOption[]>(() => {
    const year = new Date().getFullYear() + 1;
    return Array.from({ length: 101 }, (_, index) => ({
      id: year - 100 + index,
      value: `${year - 100 + index}`,
      selected: false,
      notSelectable: false,
    })).reverse();
  }, []);

  const handleClickTitle = useCallback(
    (nurseryIndex) => {
      const newNurseries = nurseries.slice();

      newNurseries[nurseryIndex].opened = !newNurseries[nurseryIndex].opened;

      setNurseries(newNurseries);
    },
    [nurseries]
  );

  const handleChangeMenu = useCallback((files) => {
    setNewMenu(files[0]);
  }, []);

  const handleClickDeleteMenu = useCallback(
    (menu) => {
      Swal.fire({
        title: 'Deseja deletar esse cardápio?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f15a29',
        cancelButtonColor: '#a6a6a6',
        cancelButtonText: 'Não',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`menus/${menu.id}`);

            console.log(menu);

            if (menu.nursery_id) {
              await loadNurseries();
            } else {
              const newMenus = apiMenus.filter(
                (menuData) => menuData.id !== menu.id
              );

              setApiMenus(newMenus);
            }

            Toast.fire({
              icon: 'success',
              title: 'Cardápio deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [apiMenus, loadNurseries]
  );

  const handleSearch = useCallback(
    (value) => {
      loadNurseries(value);
    },
    [loadNurseries]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowEdit(false);
    setNewMenu(undefined);
    setMenuSelected({} as IMenu);
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        formRef.current?.setErrors({});
        setMenusError('');

        const schema = Yup.object().shape({
          menus: Yup.string().when('$menusCheck', {
            is: (menusCheck: boolean) => menusCheck,
            then: Yup.string().required('O cardápio é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
          context: {
            menusCheck: !newMenu,
          },
        });

        const menuFormData = {
          nursery_id: menuSelected.nursery_id,
          month: menuSelected.month,
          year: menuSelected.year,
        };

        const response = await api.post('menus', menuFormData);

        const archiveFormData = new FormData();
        archiveFormData.append('menu', newMenu as File);
        archiveFormData.append('menu_id', response.data.id);
        const responseArchive = await api.post('archives', archiveFormData);

        if (menuSelected.nursery_id) {
          await loadNurseries();
        } else {
          const newMenus = apiMenus.slice();
          const menuIndex = newMenus.findIndex(
            (menu) => menu.title === menuSelected.title
          );

          if (menuIndex >= 0) {
            newMenus[menuIndex].id = response.data.id;
            newMenus[menuIndex].url = responseArchive.data.archive_url;
          } else {
            const month = format(new Date(0, response.data.month - 1), 'MMMM', {
              locale: PtBr,
            });

            const monthAux = `${month.slice(0, 1).toUpperCase()}${month.slice(
              1
            )}`;

            let color = '';
            switch (monthAux) {
              case 'Janeiro':
                color = '#22B5B9';

                break;
              case 'Fevereiro':
                color = '#7E4AC8';

                break;
              case 'Março':
                color = '#01499D';

                break;
              case 'Abril':
                color = '#108CB2';

                break;
              case 'Maio':
                color = '#D7B400';

                break;
              case 'Junho':
                color = '#CE0F3C';

                break;
              case 'Julho':
                color = '#00A189';

                break;
              case 'Agosto':
                color = '#D79B00';

                break;
              case 'Setembro':
                color = '#E7C200';

                break;
              case 'Outubro':
                color = '#E63BA2';

                break;
              case 'Novembro':
                color = '#0075FF';

                break;
              case 'Dezembro':
                color = '#F30303';

                break;

              default:
                break;
            }
            newMenus.push({
              id: response.data.id,
              archive_id: responseArchive.data.id,
              title: `${monthAux}/${yearSelected}`,
              color,
              url: responseArchive.data.archive_url,
              month: response.data.month,
              year: yearSelected,
            });
          }

          setApiMenus(newMenus);
        }

        Toast.fire({
          icon: 'success',
          title: 'Cardápio adicionado com sucesso!',
        });
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.menus) {
            setMenusError(errors.menus);
          }
        } else {
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [apiMenus, handleClose, loadNurseries, menuSelected, newMenu, yearSelected]
  );

  const handleClickEditMenu = useCallback((menu) => {
    setMenuSelected(menu);
    setShowEdit(true);
  }, []);

  const handleSubmitEditMenu = useCallback(
    async (formData) => {
      try {
        formEditRef.current?.setErrors({});
        const schema = Yup.object().shape({
          menu: Yup.string().when('$menuCheck', {
            is: (menuCheck: boolean) => menuCheck,
            then: Yup.string().required('O cardápio é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
          context: {
            menuCheck: !newMenu,
          },
        });

        const menuFormData = new FormData();
        menuFormData.append('menu', newMenu as File);
        menuFormData.append('menu_id', menuSelected.id.toString());
        const response = await api.post(
          `archives/${menuSelected.archive_id}`,
          menuFormData
        );

        if (menuSelected.nursery_id) {
          loadNurseries();
        } else {
          const newMenus = apiMenus.slice();
          const menuIndex = newMenus.findIndex(
            (menu) => menu.id === menuSelected.id
          );

          if (menuIndex >= 0) {
            newMenus[menuIndex].url = response.data.archive_url;
          }

          setApiMenus(newMenus);
        }

        Toast.fire({
          icon: 'success',
          title: 'Cardápio editado com sucesso!',
        });
        handleClose();
        loadNurseries();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.menus) {
            setMenusError(errors.menus);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [apiMenus, handleClose, loadNurseries, menuSelected, newMenu]
  );

  const handleClickNewMenu = useCallback((menu) => {
    setMenuSelected(menu);
    setShow(true);
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="text-secondary">Cardápios</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex mt-4 mb-5">
            <button
              type="button"
              className={`border-0 bg-transparent btn-option ${
                option === 'default' ? 'selected' : ''
              }`}
              onClick={() => handleClickOption('default')}
            >
              Padrão
            </button>
            <button
              type="button"
              className={`border-0 bg-transparent btn-option mx-3 ${
                option === 'single' ? 'selected' : ''
              }`}
              onClick={() => handleClickOption('single')}
            >
              Individual
            </button>
          </div>
          <div className="col-lg-6 mt-4 mb-5 d-flex justify-content-end">
            <div className="select-year">
              <button
                type="button"
                className={`btn btn-select d-flex justify-content-center align-items-center text-secondary ${
                  opened ? 'opened' : ''
                }`}
                onClick={handleClickYear}
              >
                Selecione o ano: <b className="mx-2 mb-0">{yearSelected}</b>{' '}
                <RiArrowDownSLine size={24} color="#202020" />
              </button>
              <div className={`group-years w-100 ${opened ? 'opened' : ''}`}>
                <div className="overflow-auto">
                  {years.map((year) => (
                    <button
                      key={year.id}
                      type="button"
                      className={`btn-years d-block w-100 py-2 bg-transparent border-0 fw-semibold ${
                        yearSelected === year.id ? 'selected' : ''
                      }`}
                      onClick={() => handleSelectYear(year.id)}
                    >
                      {year.value}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {option === 'default' ? (
            <div className="col-12">
              <div className="row">
                {menus.map((menu) => (
                  <div key={menu.id} className="col-lg-4 mb-4">
                    {menu.url ? (
                      <div>
                        <Menu
                          bgcolor={menu.color}
                          className="p-4 d-flex align-items-end position-relative"
                        >
                          <div>
                            <img
                              src={menusIcon}
                              alt="Icone cardápio feliz"
                              className="mb-3"
                            />
                            <p className="h3 fw-semibold">
                              Cardápio - {menu.title.slice(0, 3)}
                            </p>
                          </div>
                          <div className="d-flex options">
                            <a href={menu.url} target="_blank" rel="noreferrer">
                              <RiEyeLine size={32} color="#C9E2FF" />
                              <span className="d-block mt-1 fw-semibold">
                                Ver
                              </span>
                            </a>
                            <button
                              type="button"
                              onClick={() => handleClickEditMenu(menu)}
                            >
                              <RiPencilLine size={32} color="#fff" />
                              <span className="d-block mt-1 fw-semibold">
                                Editar
                              </span>
                            </button>
                            <button
                              type="button"
                              onClick={() => handleClickDeleteMenu(menu)}
                            >
                              <RiDeleteBinFill size={32} color="#FF1A50" />
                              <span className="d-block mt-1 fw-semibold">
                                Deletar
                              </span>
                            </button>
                          </div>
                        </Menu>
                        <h2 className="h4 mt-3 fw-normal">{menu.title}</h2>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="w-100 border-0 bg-transparent text-start"
                        onClick={() => handleClickNewMenu(menu)}
                      >
                        <div className="w-100 btn-add d-flex flex-column align-items-center justify-content-center">
                          <FiPlus size={32} className="mb-2" />{' '}
                          <span>Adicionar cardápio</span>
                        </div>
                        <h2 className="h4 mt-3 fw-normal">{menu.title}</h2>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              {nurseries.length > 0 ? (
                <>
                  {nurseries.map((nursery, index) => (
                    <>
                      <div className="col-12 pt-3 px-4">
                        <BtnTitle
                          type="button"
                          className="w-100 d-flex justify-content-between p-3"
                          onClick={() => handleClickTitle(index)}
                          opened={nursery.opened}
                        >
                          <h2 className="h5 h4-lg fw-normal bg-name mb-0">
                            {nursery.name}
                          </h2>
                          <IoIosArrowDown size={32} color="#707070" />
                        </BtnTitle>
                      </div>
                      <MenusGroup
                        opened={nursery.opened}
                        className="col-12 px-xxl-4"
                      >
                        <div className="row all mx-0 pt-4">
                          {nursery.menus.map((menu) => (
                            <div key={menu.id} className="col-lg-4 mb-4">
                              {console.log(menu)}
                              {menu.url ? (
                                <div>
                                  <Menu
                                    bgcolor={menu.color}
                                    default={menu.default}
                                    className="p-4 d-flex align-items-end position-relative"
                                  >
                                    <div className="content">
                                      <img
                                        src={menusIcon}
                                        alt="Icone cardápio feliz"
                                        className="mb-3"
                                      />
                                      <p className="h3 fw-semibold">
                                        Cardápio - {menu.title.slice(0, 3)}
                                      </p>
                                    </div>
                                    <div className="d-flex options">
                                      <a
                                        href={menu.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <RiEyeLine size={32} color="#C9E2FF" />
                                        <span className="d-block mt-1 fw-semibold">
                                          Ver {menu.default ? 'Padrão' : ''}
                                        </span>
                                      </a>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          menu.default
                                            ? handleClickNewMenu(menu)
                                            : handleClickEditMenu(menu)
                                        }
                                      >
                                        {menu.default ? (
                                          <FiPlus size={32} color="#fff" />
                                        ) : (
                                          <RiPencilLine
                                            size={32}
                                            color="#fff"
                                          />
                                        )}
                                        <span className="d-block mt-1 fw-semibold">
                                          {menu.default
                                            ? 'Adicionar'
                                            : 'Editar'}
                                        </span>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleClickDeleteMenu(menu)
                                        }
                                      >
                                        <RiDeleteBinFill
                                          size={32}
                                          color="#FF1A50"
                                        />
                                        <span className="d-block mt-1 fw-semibold">
                                          Deletar
                                        </span>
                                      </button>
                                    </div>
                                  </Menu>
                                  <h2 className="h4 mt-3 fw-normal">
                                    {menu.title}
                                  </h2>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className="w-100 border-0 bg-transparent text-start"
                                  onClick={() => handleClickNewMenu(menu)}
                                >
                                  <div className="w-100 btn-add d-flex flex-column align-items-center justify-content-center">
                                    <FiPlus size={32} className="mb-2" />{' '}
                                    <span>Adicionar cardápio</span>
                                  </div>
                                  <h2 className="h4 mt-3 fw-normal">
                                    {menu.title}
                                  </h2>
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      </MenusGroup>
                    </>
                  ))}
                </>
              ) : (
                <div className="col-12 mb-5">
                  <NoData message="Nenhum maternal cadastrado" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
            <Modal.Title className="text-secondary fw-semibold mb-0">
              Adicionar cardápio
            </Modal.Title>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={handleClose}
            >
              <MdClose size={40} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-5 my-3">
            <label className="d-block mb-3">Cardápio</label>
            <InputFile
              name="menu"
              className="input-file"
              onChange={handleChangeMenu}
            />
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5 mb-2">
            <button
              type="submit"
              className="btn btn-primary rounded-pill py-2 px-4"
            >
              Enviar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showEdit} onHide={handleClose} size="lg">
        <Form ref={formEditRef} onSubmit={handleSubmitEditMenu}>
          <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
            <Modal.Title className="text-secondary fw-semibold mb-0">
              Editar cardápio
            </Modal.Title>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={handleClose}
            >
              <MdClose size={40} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-5 my-3">
            <label className="d-block mb-3">Cardápio</label>
            <InputFile
              name="menu"
              className="input-file"
              onChange={handleChangeMenu}
            />
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5 mb-2">
            <button
              type="submit"
              className="btn btn-primary rounded-pill py-2 px-4"
            >
              Enviar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading active={loading} />
    </Container>
  );
};

export default Menus;
