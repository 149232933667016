/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { IoIosArrowDown } from 'react-icons/io';
import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { RiDeleteBinFill, RiEyeLine, RiPencilLine } from 'react-icons/ri';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Toast from '~/utils/toast';

import {
  Container,
  BtnTitle,
  Documents,
  BannerTrasparency,
  Modal,
} from './styles';
import Loading from '~/components/Loading';
import Search from '~/components/Search';
import NoData from '~/components/NoData';
import Select, { IOption } from '~/components/Select';
import InputFile from '~/components/InputFile';
import Input from '~/components/Input';

interface IArchive {
  id: number;
  nursery_id: number;
  title: string;
  archive_url: string;
}

interface INursery {
  id: number;
  name: string;
  archives: IArchive[];
  opened: boolean;
  height: number;
}

const TransparencyPortal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formEditRef = useRef<FormHandles>(null);
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [loading, setLoading] = useState(false);
  const [nurseySelected, setNurserySelected] = useState({} as INursery);
  const [documentSelected, setDocumentSelected] = useState({} as IArchive);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newDocuments, setNewDocuments] = useState<File[]>([]);
  const [newDocument, setNewDocument] = useState<File | undefined>(undefined);
  const [documentsError, setDocumentsError] = useState('');
  const [titleSelected, setTitleSelected] = useState('');

  const loadNurseries = useCallback(async (search = '') => {
    const response = await api.get<INursery[]>('nurseries', {
      params: {
        type: 'TransparencyPortal',
        search,
      },
    });

    const data = response.data.map((nursery) => ({
      ...nursery,
      opened: true,
      height: Math.ceil((nursery.archives.length + 1) / 3) * 275,
    }));

    setNurseries(data);
  }, []);

  useEffect(() => {
    setLoading(true);
    loadNurseries().finally(() => setLoading(false));
  }, [loadNurseries]);

  const data = useMemo(() => {
    let index = 0;
    let color = '';

    return nurseries.map((nursery) => {
      switch (index) {
        case 1:
          color = '#224B8F';
          break;
        case 2:
          color = '#B76522';
          index = -1;
          break;

        default:
          color = '#00A189';
          break;
      }

      index += 1;

      return {
        ...nursery,
        color,
      };
    });
  }, [nurseries]);

  const years = useMemo<IOption[]>(() => {
    const year = new Date().getFullYear();

    let yearSelected: string | undefined;
    if (Object.keys(documentSelected).length > 0) {
      const titleParts = documentSelected.title.split(' - ');
      yearSelected = titleParts[titleParts.length - 1];
    }

    return [
      {
        id: '',
        value: 'selecionar',
        selected: !yearSelected,
        notSelectable: true,
      },
      ...Array.from({ length: 101 }, (_, index) => ({
        id: year - 100 + index,
        value: `${year - 100 + index}`,
        selected: yearSelected === `${year - 100 + index}`,
        notSelectable: false,
      })).reverse(),
    ];
  }, [documentSelected]);

  const handleClickTitle = useCallback(
    (nurseryIndex) => {
      const newNurseries = nurseries.slice();

      newNurseries[nurseryIndex].opened = !newNurseries[nurseryIndex].opened;

      setNurseries(newNurseries);
    },
    [nurseries]
  );

  const handleClickNewDocument = useCallback((nursery) => {
    setNurserySelected(nursery);
    setShow(true);
  }, []);

  const handleChangeDocuments = useCallback((files) => {
    setNewDocuments(files);
  }, []);

  const handleChangeDocument = useCallback((files) => {
    setNewDocument(files[0]);
  }, []);

  const handleClickDeleteDocument = useCallback(
    (document) => {
      Swal.fire({
        title: 'Deseja deletar esse documento?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#f15a29',
        cancelButtonColor: '#a6a6a6',
        cancelButtonText: 'Não',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`archives/${document.id}`);

            loadNurseries();

            Toast.fire({
              icon: 'success',
              title: 'Documento deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [loadNurseries]
  );

  const handleSearch = useCallback(
    (value) => {
      loadNurseries(value);
    },
    [loadNurseries]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowEdit(false);
    setDocumentSelected({} as IArchive);
    setTitleSelected('');
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        formRef.current?.setErrors({});
        setDocumentsError('');

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          year: Yup.string(),
          documents: Yup.string().when('$documentsCheck', {
            is: (documentsCheck: boolean) => documentsCheck,
            then: Yup.string().required('Envie pelo menos um documento'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
          context: {
            documentsCheck: newDocuments.length === 0,
          },
        });

        await new Promise<void>((resolve) => {
          newDocuments.forEach(async (document, index) => {
            const documentFormData = new FormData();
            documentFormData.append('document', document as File);
            documentFormData.append('nursery_id', nurseySelected.id.toString());
            documentFormData.append('type', 'TransparencyPortal');
            documentFormData.append(
              'title',
              `${formData.title}${formData.year ? ` - ${formData.year}` : ''}`
            );
            await api.post('archives', documentFormData);

            if (newDocuments.length === index + 1) {
              resolve();
            }
          });
        });

        Toast.fire({
          icon: 'success',
          title: 'Documento cadastrado com sucesso!',
        });
        handleClose();
        loadNurseries();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.documents) {
            setDocumentsError(errors.documents);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [handleClose, loadNurseries, newDocuments, nurseySelected.id]
  );

  const handleClickEditDocument = useCallback((document) => {
    const titleParts = document.title.split(' - ');
    titleParts.pop();
    const titleData = titleParts.join(' - ');
    setTitleSelected(titleData);
    setDocumentSelected(document);
    setShowEdit(true);
  }, []);

  const handleSubmitEditDocument = useCallback(
    async (formData) => {
      try {
        formEditRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          year: Yup.string(),
          document: Yup.string().when('$documentCheck', {
            is: (documentCheck: boolean) => documentCheck,
            then: Yup.string().required('O documento é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(formData, {
          abortEarly: false,
          context: {
            documentCheck: !newDocument,
          },
        });

        if (newDocument) {
          const documentFormData = new FormData();
          documentFormData.append('document', newDocument as File);
          documentFormData.append(
            'nursery_id',
            documentSelected.nursery_id.toString()
          );
          documentFormData.append('type', 'TransparencyPortal');
          documentFormData.append(
            'title',
            `${formData.title}${formData.year ? ` - ${formData.year}` : ''}`
          );
          await api.post(`archives/${documentSelected.id}`, documentFormData);
        }

        Toast.fire({
          icon: 'success',
          title: 'Documento editado com sucesso!',
        });
        handleClose();
        loadNurseries();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.documents) {
            setDocumentsError(errors.documents);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      documentSelected.id,
      documentSelected.nursery_id,
      handleClose,
      loadNurseries,
      newDocument,
    ]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h1 className="text-secondary">Portal da transparência</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search" />
              </div>
            </div>
            {data.length > 0 ? (
              <>
                {data.map((nursery, index) => (
                  <>
                    <div className="col-12 pt-3 px-4">
                      <BtnTitle
                        type="button"
                        className="w-100 d-flex justify-content-between p-3"
                        onClick={() => handleClickTitle(index)}
                        opened={nursery.opened}
                      >
                        <h2 className="h5 h4-lg fw-normal bg-name mb-0">
                          {nursery.name}
                        </h2>
                        <IoIosArrowDown size={32} color="#707070" />
                      </BtnTitle>
                    </div>
                    <Documents
                      opened={nursery.opened}
                      className="col-12 px-xxl-4"
                      height={nursery.height}
                    >
                      <div className="row all pt-4 mx-0">
                        <div className="col-lg-4 mb-4">
                          <button
                            type="button"
                            className="w-100 btn-add d-flex flex-column align-items-center justify-content-center"
                            onClick={() => handleClickNewDocument(nursery)}
                          >
                            <FiPlus size={32} className="mb-2" />{' '}
                            <span>Novo documento</span>
                          </button>
                        </div>
                        {nursery.archives.map((archive) => (
                          <div className="col-lg-4 mb-4">
                            <BannerTrasparency color={nursery.color}>
                              <div className="w-100 h-100 d-flex flex-column justify-content-end px-4 py-5 px-xl-5">
                                <h5 className="h5 fw-bold text-white">
                                  {archive.title}
                                </h5>
                              </div>
                              <div className="d-flex options">
                                <a
                                  href={archive.archive_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <RiEyeLine size={32} color="#C9E2FF" />
                                  <span className="d-block mt-1 fw-semibold">
                                    Ver
                                  </span>
                                </a>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleClickEditDocument(archive)
                                  }
                                >
                                  <RiPencilLine size={32} color="#fff" />
                                  <span className="d-block mt-1 fw-semibold">
                                    Editar
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleClickDeleteDocument(archive)
                                  }
                                >
                                  <RiDeleteBinFill size={32} color="#FF1A50" />
                                  <span className="d-block mt-1 fw-semibold">
                                    Deletar
                                  </span>
                                </button>
                              </div>
                            </BannerTrasparency>
                          </div>
                        ))}
                      </div>
                    </Documents>
                  </>
                ))}
              </>
            ) : (
              <div className="col-12 mb-5">
                <NoData message="Nenhum maternal cadastrado" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
            <Modal.Title className="text-secondary fw-semibold mb-0">
              Novo documento
            </Modal.Title>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={handleClose}
            >
              <MdClose size={40} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-5 my-3">
            <label className="mb-3 w-100">
              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                Título
              </span>
              <Input name="title" placeholder="Digite aqui" />
            </label>
            <label className="mb-3 w-100">
              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                Ano
              </span>
              <Select
                name="year"
                placeholder="Digite aqui"
                onlySelect
                options={years}
              />
            </label>
            <label className="d-block mb-3">Documento</label>
            <InputFile
              name="documents"
              multiple
              className="input-file"
              onChange={handleChangeDocuments}
            />
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5 mb-2">
            <button
              type="submit"
              className="btn btn-primary rounded-pill py-2 px-4"
            >
              Enviar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showEdit} onHide={handleClose} size="lg">
        <Form ref={formEditRef} onSubmit={handleSubmitEditDocument}>
          <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
            <Modal.Title className="text-secondary fw-semibold mb-0">
              Editar documento
            </Modal.Title>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={handleClose}
            >
              <MdClose size={40} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-5 my-3">
            <label className="mb-3 w-100">
              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                Título
              </span>
              <Input
                name="title"
                placeholder="Digite aqui"
                defaultValue={titleSelected}
              />
            </label>
            <label className="mb-3 w-100">
              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                Ano
              </span>
              <Select
                name="year"
                placeholder="Digite aqui"
                onlySelect
                options={years}
              />
            </label>
            <label className="d-block mb-3">Documento</label>
            <InputFile
              name="document"
              className="input-file"
              onChange={handleChangeDocument}
            />
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5 mb-2">
            <button
              type="submit"
              className="btn btn-primary rounded-pill py-2 px-4"
            >
              Enviar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading active={loading} />
    </Container>
  );
};

export default TransparencyPortal;
