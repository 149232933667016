import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import InputPhoto from '~/components/InputPhoto';
import Textarea from '~/components/Textarea';
import InputEditor, { IFile } from '~/components/InputEditor';

import api from '~/services/api';
import Toast from '~/utils/toast';
import Select, { IOption } from '~/components/Select';

interface INursery {
  id: number;
  name: string;
}

interface IFormData {
  title: string;
  nursery: string;
  description: string;
  body: string;
}

const NoticeRegister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [banner, setBanner] = useState<File | undefined>();
  const [bannerError, setBannerError] = useState('');
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [published, setSetPublished] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          id: nursery.id,
          value: nursery.name,
          selected: false,
          notSelectable: false,
        }));
        data.unshift({
          id: '',
          value: 'selecionar',
          selected: true,
          notSelectable: true,
        });
        setNurseries(data);
      });
  }, []);

  const handleChangeBanner = useCallback((file) => {
    setBanner(file);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setBannerError('');

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          nursery: Yup.string().required('O maternal é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          body: Yup.string().required('O conteúdo é obrigatório'),
          banner: Yup.string().when('$bannerCheck', {
            is: (bannerCheck: boolean) => bannerCheck,
            then: Yup.string().required('O banner é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            bannerCheck: !banner,
          },
        });

        let { body } = data;

        const formData = {
          nursery_id: data.nursery,
          title: data.title,
          description: data.description,
          body,
          published,
        };

        const response = await api.post('notices', formData);

        const bannerFormData = new FormData();
        bannerFormData.append('notice', banner as File);
        bannerFormData.append('notice_id', response.data.id);
        bannerFormData.append('type', 'banner');
        await api.post('archives', bannerFormData);

        if (files.length > 0) {
          await new Promise<void>((resolve) => {
            files.forEach(async (file, index) => {
              const fileFormData = new FormData();
              fileFormData.append('notice', file.file);
              fileFormData.append('notice_id', response.data.id);
              fileFormData.append('type', 'body');
              const responseFile = await api.post('archives', fileFormData);

              body = body.replace(file.file_url, responseFile.data.archive_url);

              if (files.length === index + 1) {
                resolve();
              }
            }, []);
          });
        }

        formData.body = body;

        await api.put(`notices/${response.data.id}`, formData);

        Toast.fire({
          icon: 'success',
          title: 'Notícia cadastrada com sucesso!',
        });
        history.push(`${process.env.PUBLIC_URL}/noticias`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.banner) {
            setBannerError(errors.banner);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [banner, files, history, published]
  );

  const handleClickSubmit = useCallback((willPublished) => {
    setSetPublished(willPublished);
    setTimeout(() => {
      formRef.current?.submitForm();
    }, 100);
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row align-items-center px-3 px-sm-0">
          <div className="col-6">
            <h1 className="text-secondary">Cadastrar notícia</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="box py-3 px-3 px-lg-5">
              <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="white-box w-100 py-5 mt-5"
                >
                  <div className="form-box">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Título
                          </span>
                          <Input name="title" placeholder="Digite aqui" />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Notícia
                          </span>
                          <Select
                            name="nursery"
                            placeholder="Digite aqui"
                            onlySelect
                            options={nurseries}
                          />
                        </label>
                      </div>
                      <div className="col-12">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Descrição
                          </span>
                          <Textarea
                            name="description"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Banner
                            </span>
                          </label>
                          <InputPhoto
                            name="banner"
                            onChange={handleChangeBanner}
                            hasError={!!bannerError}
                          />
                        </div>
                        <div>
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Conteúdo
                            </span>
                          </label>
                          <InputEditor name="body" onChangeFiles={setFiles} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 form-box">
                    <div className="row justify-content-end">
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill py-2 px-4 w-100 w-lg-auto me-3"
                          onClick={() => handleClickSubmit(false)}
                        >
                          Salvar
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill py-2 px-4 w-100 w-lg-auto"
                          onClick={() => handleClickSubmit(true)}
                        >
                          Salvar e publicar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NoticeRegister;
