import React, { useCallback, useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { Container, Tag } from './styles';
import Input from '../Input';

interface IInputTags {
  name: string;
  onChange?(tags: string[]): void;
  value?: string;
}

const InputTags: React.FC<IInputTags> = ({
  name,
  onChange,
  value: defaultValue,
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setTags(defaultValue.split('; '));
    }
  }, [defaultValue]);

  useEffect(() => {
    const data = tags.join('; ');
    setValue(data);
  }, [tags]);

  const handleKeyDown = useCallback(
    (e) => {
      const newTag = e.target.value;
      if (e.keyCode === 186 || e.which === 186) {
        e.preventDefault();
        if (onChange) {
          onChange([...tags, newTag]);
        }
        setTags((state) => [...state, newTag]);
        e.target.value = '';
      } else if ((e.keyCode === 8 || e.which === 8) && newTag.length === 0) {
        e.preventDefault();
        const newTags = tags.slice();
        const lastTag = newTags.pop();
        e.target.value = lastTag || '';
        if (onChange) {
          onChange(newTags);
        }
        setTags(newTags);
      }
    },
    [onChange, tags]
  );

  const handleClickRemoveTag = useCallback((tagIndex) => {
    setTags((state) => state.filter((_, index) => index !== tagIndex));
  }, []);

  const handleChange = useCallback((e) => {
    console.log(e);
  }, []);

  return (
    <Container className="d-flex flex-wrap activities">
      {tags.map((tag, index) => (
        <Tag
          key={`${tag}-${index.toString()}`}
          className="d-flex aling-items-center"
        >
          <span>{tag}</span>
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={(e) => {
              e.stopPropagation();
              handleClickRemoveTag(index);
            }}
          >
            <MdClose size={24} color="#202020" className="ms-2" />
          </button>
        </Tag>
      ))}
      <input
        onKeyDown={handleKeyDown}
        className="border-0 bg-transparent input-tag"
        placeholder="Digite aqui..."
      />
      <Input
        name={name}
        className="d-none"
        value={value}
        onChange={handleChange}
      />
    </Container>
  );
};

export default InputTags;
