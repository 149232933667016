/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { PDFViewer } from '@react-pdf/renderer';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Avatar, Options, Modal } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';
import ProfileDocument from './ProfileDocument';

import avatar from '~/assets/defaults/avatar-default.svg';
import mask from '~/utils/mask';

interface ISupplierResponse {
  id: number;
  president_name: string;
  phone: string;
  email: string;
  rg: string;
  cpf: string;
  company: string;
  cnpj: string;
  cnae: string;
  created_at: string;
  avatar?: {
    avatar_url: string;
  };
}

interface ISupplierData {
  data: ISupplierResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface ISupplier {
  id: string;
  supplier_id: number;
  company: string;
  avatar: string;
  name: string;
  email: string;
  joined_at: string;
}

interface ISupplierDocument {
  companyName: string;
  email: string;
  phone: string;
  cnpj: string;
  cnae?: string;
  presidentName: string;
  cpf?: string;
  rg?: string;
  address?: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Suppliers: React.FC = () => {
  const location = useLocation();
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [supplierSelected, setSupplierSelected] = useState({} as ISupplier);
  const [showDocument, setShowDocument] = useState(false);
  const [supplierDocument, setSupplierDocument] = useState(
    {} as ISupplierDocument
  );

  const type = useMemo(
    () =>
      location.pathname.includes('fornecedores')
        ? 'fornecedores'
        : 'prestadores',
    [location.pathname]
  );

  const loadSuppliers = useCallback(
    async (pageData, search = '') => {
      const response = await api.get<ISupplierData>('suppliers', {
        params: { page: pageData, search, isProvider: type === 'prestadores' },
      });

      const data = response.data.data.map<ISupplier>((supplier, index) => {
        const [firstName, secondName] = supplier.president_name.split(' ');
        return {
          id: (pageData * index + 1).toString().padStart(5, '0'),
          supplier_id: supplier.id,
          company: supplier.company,
          avatar: supplier.avatar ? supplier.avatar.avatar_url : avatar,
          name: `${firstName} ${secondName}`,
          email: supplier.email,
          joined_at: format(parseISO(supplier.created_at), 'dd/MM/yyyy'),
        };
      });

      setSuppliers(data);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        current_page: response.data.current_page,
      });
    },
    [type]
  );

  useEffect(() => {
    setLoading(true);
    loadSuppliers(page).finally(() => setLoading(false));
  }, [loadSuppliers, page]);

  const handleClickOutside = useCallback((event) => {
    const swalElements = document.getElementsByClassName(
      'swal2-container'
    ) as unknown as HTMLElement[];
    const btnElements = document.getElementsByClassName(
      'btn-option'
    ) as unknown as HTMLElement[];
    const elements = document.getElementsByClassName(
      'options'
    ) as unknown as HTMLElement[];
    let close = true;
    for (const element of elements) {
      for (const btnElement of btnElements) {
        if (
          btnElement.contains(event.target) ||
          element.contains(event.target)
        ) {
          close = false;
        }

        if (swalElements.length > 0) {
          for (const swalElement of swalElements) {
            if (swalElement.contains(event.target)) {
              close = false;
            }
          }
        }
      }
    }

    if (close) {
      setSupplierSelected({} as ISupplier);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickDots = useCallback((supplier: ISupplier) => {
    setSupplierSelected((state) =>
      state.id === supplier.id ? ({} as ISupplier) : supplier
    );
  }, []);

  // const handleClickLoginSupplier = useCallback(async () => {
  //   const response = await api.post('tokens/admin-supports', {
  //     supplier_id: supplierSelected.supplier_id,
  //   });
  //   window.open(
  //     `${process.env.REACT_APP_APP_URL}/auto-login/${response.data}`,
  //     '_blank'
  //   );
  // }, [supplierSelected.supplier_id]);

  const handleClickDeleteSupplier = useCallback(() => {
    Swal.fire({
      title: `Deseja deletar esse ${
        type === 'fornecedores' ? 'fornecedor' : 'prestador'
      }?`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`suppliers/${supplierSelected.supplier_id}`);

          const newSuppliers = suppliers.filter(
            (suppliersData) =>
              suppliersData.supplier_id !== supplierSelected.supplier_id
          );
          setSuppliers(newSuppliers);

          Toast.fire({
            icon: 'success',
            title: 'Fornecedor deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [supplierSelected.supplier_id, suppliers, type]);

  const handleClickPrintProfile = useCallback(async () => {
    const response = await api.get(`suppliers/${supplierSelected.supplier_id}`);
    setSupplierDocument({
      companyName: response.data.company,
      email: response.data.email,
      phone: mask({
        kind: 'cel-phone',
        value: response.data.phone,
      }),
      cnpj: response.data.cnpj,
      cnae: response.data.cnae,
      presidentName: response.data.president_name,
      cpf: response.data.cpf,
      rg: response.data.rg,
      address: response.data.address
        ? `${response.data.address.street} nº ${
            response.data.address.number
          } - ${
            response.data.address.complement
              ? `${response.data.address.complement} -`
              : ''
          } ${response.data.address.neighborhood} - CEP ${mask({
            kind: 'zip-code',
            value: response.data.address.zip_code,
          })}, ${response.data.address.city}/${response.data.address.state}`
        : undefined,
    });
    setShowDocument(true);
  }, [supplierSelected.supplier_id]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Empresa',
        selector: 'company',
      },
      {
        name: 'Representante',
        selector: 'name',
        cell: (row: ISupplier) => (
          <div className="d-flex align-items-center">
            <Avatar src={row.avatar} className="me-2" />
            <p className="mb-0">{row.name}</p>
          </div>
        ),
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Data de ingresso',
        selector: 'joined_at',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: ISupplier) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent btn-option"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options
              className="options"
              active={supplierSelected.id === row.id}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/${type}/${row.supplier_id}/empresa`}
              >
                Ver perfil
              </Link>
              <button type="button" onClick={handleClickPrintProfile}>
                Imprimir o perfil
              </button>
              {/* <button type="button" onClick={handleClickLoginSupplier}>
                Logar como o fornecedor
              </button> */}
              <button type="button" onClick={handleClickDeleteSupplier}>
                Deletar {type === 'fornecedores' ? 'fornecedor' : 'prestador'}
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickDeleteSupplier,
      handleClickDots,
      handleClickPrintProfile,
      supplierSelected,
      type,
    ]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadSuppliers(1, value);
    },
    [loadSuppliers]
  );

  const handleClose = useCallback(() => {
    setShowDocument(false);
    setSupplierDocument({} as ISupplierDocument);
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h1 className="text-secondary">
                {type === 'fornecedores' ? 'Fornecedores' : 'Prestadores'}
              </h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <Link
                  to={`${process.env.PUBLIC_URL}/${type}/novo/empresa`}
                  className="btn btn-primary rounded-pill py-2 px-4 d-flex aling-items-center ms-3"
                >
                  <FiPlus size={22} color="#fff" className="me-2" /> Novo
                </Link>
              </div>
            </div>
            <Table
              columns={columns}
              data={suppliers}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-suppliers mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Loading active={loading} />
      <Modal show={showDocument} onHide={handleClose}>
        <Modal.Body>
          {Object.keys(supplierDocument).length > 0 && (
            <PDFViewer className="w-100 vh-100">
              <ProfileDocument
                type={type === 'fornecedores' ? 'Fornecedor' : 'Prestador'}
                date={format(new Date(), "dd/MM/yyyy 'às' hh:mm")}
                companyName={supplierDocument.companyName}
                email={supplierDocument.email}
                phone={supplierDocument.phone}
                address={supplierDocument.address}
                cnpj={supplierDocument.cnpj}
                cnae={supplierDocument.cnae}
                presidentName={supplierDocument.presidentName}
                cpf={supplierDocument.cpf}
                rg={supplierDocument.rg}
              />
            </PDFViewer>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Suppliers;
