import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  min-height: 46px;
  position: relative;

  .editor {
    min-height: 300px;
  }

  .error-box {
    position: absolute;
    bottom: -35px;
  }
`;
