import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

interface IOptions {
  active: boolean;
}

export const Container = styled.div`
  min-height: calc(100vh - 70px);

  .table-suppliers {
    padding-top: 0;

    > div {
      .content {
        padding-top: 10px;

        .tb-header {
          border: none;

          button {
            color: #202020;
          }
        }

        .tb-body {
          .tb-row {
            transition-duration: 0.3s;
            border-radius: 16px;

            :hover {
              background-color: ${darken(0.03, '#fff')};
            }
          }

          div {
            color: #707070;
          }
        }

        .tb-header .tb-row,
        .tb-body .tb-row {
          > button:nth-child(1),
          > div:nth-child(1) {
            width: 100px;
          }

          > button:nth-child(2),
          > div:nth-child(2) {
            width: 250px;
          }

          > button:nth-child(3),
          > div:nth-child(3) {
            width: 250px;
          }

          > button:nth-child(4),
          > div:nth-child(4) {
            width: 300px;
          }

          > button:nth-child(5),
          > div:nth-child(5) {
            width: 250px;
          }

          > button:nth-child(6),
          > div:nth-child(6) {
            width: 60px;
          }
        }
      }

      :after,
      :before {
        border: none;
      }

      :nth-child(2) {
        select {
          color: #bbbbbb;
        }

        small {
          color: #bbbbbb;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 1640px) {
    .table-suppliers {
      > div {
        .content {
          overflow: unset;
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

export const Options = styled.div<IOptions>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  background: #fff;
  border: 2px solid #e4e4e4;
  width: max-content;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -53px;
  left: -95px;
  transform: translateX(-50%);

  a,
  button {
    background: transparent;
    border: none;
    text-align: center;
    color: #202020;
    padding: 8px 0;

    + button,
    + a {
      border-top: 1px solid #e4e4e4;
    }
  }

  :after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    border-right: 2px solid #e4e4e4;
    border-bottom: 2px solid #e4e4e4;
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: rotateZ(-45deg) translateX(-50%);
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-dialog {
    max-width: 95vw;

    .modal-content {
      border-radius: 16px;
    }
  }
`;
