import styled from 'styled-components';

export const Container = styled.div`
  min-height: calc(100vh - 70px);

  .box-process {
    border-radius: 32px;
    overflow: hidden;

    .see-process {
      > div {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .box-process.open {
    background: #00a189;

    .see-process {
      background: #066456;

      > div {
        background: #489489;
      }
    }
  }

  .box-process.in-progress {
    background: #4589da;

    .see-process {
      background: #0d4485;

      > div {
        background: #5982b3;
      }
    }
  }

  .box-process.finished {
    background: #e6883b;

    .see-process {
      background: #a74b00;

      > div {
        background: #eca469;
      }
    }
  }

  .btn-date {
    border-radius: 100px;
    border: 1px solid #ebebeb;
    background: #fff;
  }

  .quotation-box {
    border-radius: 13px;
    border: 1px solid #d7d7d7;

    .title {
      width: 100%;
    }

    .tag-box {
      .tag {
        border-radius: 100px;
      }

      .tag.open {
        background: #c5f0ea;
      }

      .tag.in-progress {
        background: #c9e2ff;
      }

      .tag.finished {
        background: #fff5be;
      }
    }

    + .quotation-box {
      margin-top: 13px;
    }
  }

  .btn-pagination {
    display: flex;
    width: 37px;
    height: 37px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;

    + .btn-pagination {
      margin-left: 10px;
    }
  }

  .btn-pagination.arrow,
  .btn-pagination.selected,
  .btn-pagination:not(.ellipsis):hover {
    border: 1px solid #707070;
  }

  @media screen and (min-width: 400px) {
    .btn-pagination {
      width: 45px;
      height: 45px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 992px) {
    .quotation-box {
      .title {
        width: calc(100% - 600px);
      }

      .tag-box {
        width: 175px;
      }
    }

    .btn-pagination {
      width: 64px;
      height: 64px;
      padding: 20px;
      font-size: 18px;

      + .btn-pagination {
        margin-left: 20px;
      }
    }
  }
`;
