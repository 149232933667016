/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { TbUpload } from 'react-icons/tb';
import { useDropzone } from 'react-dropzone';

import { HiOutlineTrash } from 'react-icons/hi2';
import { Container, Dropzone } from './styles';
import Input from '~/components/Input';

interface IInputFile {
  name: string;
  className?: string;
  onChange?(files: File[]): void;
  value?: string;
  multiple?: boolean;
  accept?: string;
  filesValue?: File[];
}

const InputFile: React.FC<IInputFile> = ({
  name,
  className,
  onChange,
  value,
  multiple,
  accept,
  filesValue,
  children,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [valuePreview, setValuePreview] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [oldFiles, setOldFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  const handleChange = useCallback((e) => {
    setFiles((state) => [...state, ...e.target.files]);
  }, []);

  useEffect(() => {
    setFiles((state) => [...state, ...acceptedFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (filesValue) {
      setFiles(filesValue);
    }
  }, [filesValue]);

  useEffect(() => {
    if (onChange && files.length > 0) {
      if (files !== oldFiles) {
        setOldFiles(files);
        onChange(files);
      }
    }
  }, [files, oldFiles, onChange]);

  const handleClickRemove = useCallback(
    (fileIndex) => {
      const input = document.getElementById(name) as HTMLInputElement;
      if (input) {
        input.value = '';
      }
      setFiles((state) => state.filter((_, index) => index !== fileIndex));
      setOldFiles((state) => state.filter((_, index) => index !== fileIndex));
    },
    [name]
  );

  return (
    <Container
      className={className}
      isErrored={isError}
      isFilled={isFilled}
      isFocuses={isFocuses}
      active={files.length > 0}
      multiple={multiple}
    >
      <label htmlFor={name} className={children ? 'no-styles' : ''}>
        <Dropzone
          {...getRootProps({ className: 'w-100' })}
          onClick={(event) => event.stopPropagation()}
        >
          {children || (
            <>
              {files.length > 0 ? (
                <div>
                  {files.map((file, index) => (
                    <div
                      className="d-flex align-items-center justify-content-between py-2 mx-2 file"
                      title={file.name}
                    >
                      <p className="px-3 mb-0">{file.name}</p>
                      {multiple && (
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={() => handleClickRemove(index)}
                        >
                          <HiOutlineTrash size={24} color="#F3465B" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {!multiple && value ? (
                    <div className="d-flex align-items-center" title={value}>
                      <p className="px-3 mb-0">{value}</p>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <TbUpload size={20} color="#a3a3a3" className="ms-lg-3" />
                      <p className="mb-0 w-100 text-start">
                        {!multiple
                          ? 'Subir documento'
                          : 'Envie um ou mais documentos'}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <input {...getInputProps()} name={name} />
        </Dropzone>
        <Input
          type="file"
          name={name}
          id={name}
          multiple={multiple}
          className="d-none"
          onChange={handleChange}
          accept={accept}
        />
      </label>
      {!multiple && files.length > 0 && (
        <button
          type="button"
          className="border-0 bg-transparent"
          onClick={() => handleClickRemove(0)}
        >
          <HiOutlineTrash size={24} color="#F3465B" />
        </button>
      )}
    </Container>
  );
};

export default InputFile;
