import React from 'react';

import Footer from '~/components/Footer';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};

export default AuthLayout;
