/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Avatar, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';

import avatar from '~/assets/defaults/avatar-default.svg';

interface INurseryResponse {
  id: number;
  cnpj: string;
  name: string;
  email: string;
  primary_phone: string;
  secondary_phone: string;
  story: string;
  slug: string;
  banner: {
    archive_url: string;
  };
}

interface INurseryData {
  data: INurseryResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface INursery {
  id: string;
  nursery_id: number;
  name: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Nurseries: React.FC = () => {
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [nurserySelected, setNurserySelected] = useState({} as INursery);

  const loadNurseries = useCallback(async (pageData, search = '') => {
    const response = await api.get<INurseryData>('nurseries', {
      params: { page: pageData, search },
    });

    const data = response.data.data.map<INursery>((nursery, index) => {
      return {
        id: (pageData * index + 1).toString().padStart(5, '0'),
        nursery_id: nursery.id,
        name: nursery.name,
      };
    });

    setNurseries(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadNurseries(page).finally(() => setLoading(false));
  }, [loadNurseries, page]);

  const handleClickOutside = useCallback((event) => {
    const swalElements = document.getElementsByClassName(
      'swal2-container'
    ) as unknown as HTMLElement[];
    const btnElements = document.getElementsByClassName(
      'btn-option'
    ) as unknown as HTMLElement[];
    const elements = document.getElementsByClassName(
      'options'
    ) as unknown as HTMLElement[];
    let close = true;
    for (const element of elements) {
      for (const btnElement of btnElements) {
        if (
          btnElement.contains(event.target) ||
          element.contains(event.target)
        ) {
          close = false;
        }

        if (swalElements.length > 0) {
          for (const swalElement of swalElements) {
            if (swalElement.contains(event.target)) {
              close = false;
            }
          }
        }
      }
    }

    if (close) {
      setNurserySelected({} as INursery);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickDots = useCallback((nursery: INursery) => {
    setNurserySelected((state) =>
      state.id === nursery.id ? ({} as INursery) : nursery
    );
  }, []);

  const handleClickDeleteNursery = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse maternal?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`nurseries/${nurserySelected.nursery_id}`);

          loadNurseries(page);
          setNurserySelected({} as INursery);

          Toast.fire({
            icon: 'success',
            title: 'Maternal deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [loadNurseries, nurserySelected.nursery_id, page]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: INursery) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent btn-option"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options className="options" active={nurserySelected.id === row.id}>
              <Link
                to={`${process.env.PUBLIC_URL}/maternais/${row.nursery_id}`}
              >
                Editar maternal
              </Link>
              <button type="button" onClick={handleClickDeleteNursery}>
                Deletar maternal
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [handleClickDeleteNursery, handleClickDots, nurserySelected.id]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadNurseries(1, value);
    },
    [loadNurseries]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h1 className="text-secondary">Maternais</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <Link
                  to={`${process.env.PUBLIC_URL}/maternais/novo`}
                  className="btn btn-primary rounded-pill py-2 px-4 d-flex aling-items-center ms-3"
                >
                  <FiPlus size={22} color="#fff" className="me-2" /> Nova
                </Link>
              </div>
            </div>
            <Table
              columns={columns}
              data={nurseries}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-nurseries mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default Nurseries;
