/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import { MdClose } from 'react-icons/md';
import { BsTrashFill } from 'react-icons/bs';
import { VscInfo } from 'react-icons/vsc';
import { useParams } from 'react-router-dom';

import Toast from '~/utils/toast';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';

interface INursery {
  id: number;
  name: string;
}

interface IFormData {
  title: string;
  expiryDate: string;
}

interface INewProcess {
  onSucceededAddNewProcess?(): void;
}

interface IParams {
  type: string;
}

const NewProcess: React.FC<INewProcess> = ({ onSucceededAddNewProcess }) => {
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState<File[]>([]);
  const [documentsInput, setDocumentsInput] = useState<string[]>([
    'document-1',
  ]);
  const [errorDocuments, setErrorDocuments] = useState('');
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [nurseriesError, setNurseriesError] = useState('');
  const [nurseriesOption, setNurseriesOption] = useState<IOption[]>([]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          value: nursery.id,
          label: nursery.name,
          selected: false,
        }));
        setNurseries(data);
      });
  }, []);

  const handleClose = useCallback(() => {
    setDocuments([]);
    setShow(false);
  }, []);

  const handleClick = useCallback(() => {
    setShow(true);
  }, []);

  const handleChangeDocument = useCallback((files: File[], index) => {
    setDocuments((state) => {
      const newDocuments = state.slice();
      if (newDocuments[index]) {
        newDocuments[index] = files[0];

        return newDocuments;
      }

      return [...newDocuments, files[0]];
    });
  }, []);

  const handleClickAddDocument = useCallback(() => {
    setDocumentsInput((state) => [...state, `documento-${state.length + 1}`]);
  }, []);

  const handleClickRemoveDocument = useCallback(
    (index) => {
      if (documentsInput.length > 1) {
        setDocumentsInput((state) => state.filter((_, idx) => idx !== index));
      }

      setDocuments((state) => state.filter((_, idx) => idx !== index));
    },
    [documentsInput.length]
  );

  const handleChangeNurseries = useCallback((nurseriesOptions) => {
    setNurseriesOption(nurseriesOptions);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setErrorDocuments('');
        setNurseriesError('');
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          nurseries: Yup.string().when('$nurseriesCheck', {
            is: (nurseriesCheck: boolean) => nurseriesCheck,
            then: Yup.string().required('Escolha pelo menos um maternal'),
            otherwise: Yup.string(),
          }),
          expiryDate: Yup.string().required(
            'A data de vencimento é obrigatória'
          ),
          documents: Yup.string().when('$documentsCheck', {
            is: (documentsCheck: boolean) => documentsCheck,
            then: Yup.string().required('Ao menos um documento é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        const nurseriesSelected = nurseriesOption.filter(
          (nursery) => nursery.selected
        );

        await schema.validate(data, {
          abortEarly: false,
          context: {
            documentsCheck: documents.length === 0,
            nurseriesCheck: nurseriesSelected.length === 0,
          },
        });

        const date = parseISO(data.expiryDate).setHours(23, 59, 59, 99);

        const formData = {
          title: data.title,
          status: 'Em aberto',
          expiry_date: format(date, "yyyy-MM-dd'T'HH:mm:ss"),
          type: params.type === 'fornecedores' ? 'supplier' : 'provider',
        };

        const response = await api.post('processes', formData);

        if (nurseriesSelected.length > 0) {
          await new Promise<void>((resolve) => {
            nurseriesSelected.forEach(async (nursery, index) => {
              await api.post('processes-nurseries', {
                process_id: response.data.id,
                nursery_id: nursery.value,
              });

              if (nurseriesSelected.length === index + 1) {
                resolve();
              }
            });
          });
        }

        if (documents.length > 0) {
          await new Promise<void>((resolve) => {
            documents.forEach(async (document, index) => {
              const documentFormData = new FormData();
              documentFormData.append('document', document);
              documentFormData.append('process_id', response.data.id);
              documentFormData.append('title', 'Documento');
              await api.post('archives', documentFormData);

              if (documents.length === index + 1) {
                resolve();
              }
            });
          });
        }

        Toast.fire({
          icon: 'success',
          title: 'Processo criado!',
        });

        if (onSucceededAddNewProcess) {
          onSucceededAddNewProcess();
        }
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (errors.documents) {
            setErrorDocuments(errors.documents);
          }
          if (errors.nurseries) {
            setNurseriesError(errors.nurseries);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      documents,
      handleClose,
      nurseriesOption,
      onSucceededAddNewProcess,
      params.type,
    ]
  );

  return (
    <Container>
      <button
        type="button"
        className="btn btn-primary rounded-pill py-2 px-4 d-flex aling-items-center ms-3"
        onClick={handleClick}
      >
        <FiPlus size={22} color="#fff" className="me-2" /> Novo
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center">
            <Modal.Title className="text-secondary fw-semibold mb-0">
              Novo processo
            </Modal.Title>
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={handleClose}
            >
              <MdClose size={40} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-5">
            <label className="d-block mb-4">
              <span className="d-block mb-2 text-secondary fw-medium">
                Título
              </span>
              <Input name="title" className="input mt-2" />
            </label>
            <label className="d-block mb-4">
              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                Maternal
              </span>
              <InputCheckbox
                type="checkbox"
                name="nurseries"
                options={nurseries}
                className="input-checkbox"
                onChange={handleChangeNurseries}
                error={nurseriesError}
              />
            </label>
            <label className="d-block mb-4">
              <span className="d-block mb-2 text-secondary fw-medium">
                Data de vencimento
              </span>
              <Input type="date" name="expiryDate" className="input mt-2" />
            </label>
            <label className="d-block mt-4">
              <span className="d-block mb-2 text-secondary fw-medium">
                Documentos
              </span>
            </label>
            {documentsInput.map((documentInput, index) => (
              <div className="d-flex align-items-center mt-2">
                <InputFile
                  name={`${documentInput}-${index}`}
                  className="input-file"
                  onChange={(files) => handleChangeDocument(files, index)}
                />
                <button
                  type="button"
                  className="btn"
                  onClick={() => handleClickRemoveDocument(index)}
                >
                  <BsTrashFill size={24} color="#FF1A50" />
                </button>
              </div>
            ))}
            {errorDocuments && (
              <div className="w-100 d-flex align-items-center mt-1 error-box">
                <VscInfo size={24} color="#FF1A50" className="me-1" />
                <span className="small text-danger error mb-0">
                  {errorDocuments}
                </span>
              </div>
            )}
            <button
              type="button"
              onClick={handleClickAddDocument}
              className="btn btn-add-input d-flex align-items-center mt-2"
            >
              <FiPlus size={22} color="#777" className="me-2" /> Adicionar mais
              documento
            </button>
          </Modal.Body>
          <Modal.Footer className="border-0 pb-4 px-5 mb-2">
            <button
              type="submit"
              className="btn btn-primary rounded-pill py-2 px-4"
            >
              Enviar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default NewProcess;
