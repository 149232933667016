import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form } from '@unform/web';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { RiQuestionLine } from 'react-icons/ri';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { TbCameraPlus } from 'react-icons/tb';
import { IoIosArrowDown } from 'react-icons/io';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Avatar, Tabs, TabsButton, TabsGroup } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputFile from '~/components/InputFile';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import Toast from '~/utils/toast';

interface IFormData {
  name: string;
  email: string;
  password: string;
}

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O CNPJ é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatório'),
          password: Yup.string(),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas devem ser iguais'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        await api.put(`users/${user.id}`, formData);

        updateUser({
          ...user,
          name: data.name,
          email: data.email,
        });

        Toast.fire({
          icon: 'success',
          title: 'Informações atualizadas!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [updateUser, user]
  );

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="container"
        initialData={user}
      >
        <div className="row mb-5">
          <div className="col-12 mt-5 mb-0 my-lg-5">
            <h1 className="h3 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
              Perfil
            </h1>
          </div>
          <div className="col-12">
            <div className="box py-4 px-3 p-lg-5">
              <div className="row">
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Nome
                    </span>
                    <Input name="name" placeholder="Digite aqui" />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      E-mail
                    </span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Digite aqui"
                    />
                  </label>
                </div>
                <div className="col-12 mt-3 mb-2">
                  <h3 className="h5 text-secondary">Mudar senha</h3>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Digite uma senha
                    </span>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Digite aqui"
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Confirmar senha
                    </span>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder="Digite aqui"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              type="submit"
              className="btn btn-primary rounded-pill px-5 py-2 w-100 w-lg-auto"
            >
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default Profile;
