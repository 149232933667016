import styled from 'styled-components';

export const Container = styled.div`
  .process-qtd {
    border-radius: 100px;
    border: 1px solid #e8e8e8;
    background: #eaeaea;
  }

  .quotations {
    height: calc(100vh - 290px);
    overflow: auto;

    ::-webkit-scrollbar-thumb {
      background-color: #00a189;
    }
  }
`;
