import React, { ReactElement, useEffect, useState } from 'react';

import { IconType } from 'react-icons';

interface IIcon {
  title: string;
}

const Icon: React.FC<IIcon> = ({ title }) => {
  const [IconComponent, setIconComponent] = useState<IconType | undefined>(
    undefined
  );

  useEffect(() => {
    if (title) {
      const library = title.slice(0, 2).toLowerCase();
      if (library) {
        switch (library) {
          case 'ci':
            import(`react-icons/ci`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'fa':
            import(`react-icons/fa`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'fa6':
            import(`react-icons/fa6`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'io':
            import(`react-icons/io`).then((module) => {
              if (module[title]) {
                setIconComponent(module[title] as IconType);
              } else {
                import(`react-icons/io5`).then((subModule) => {
                  setIconComponent(subModule[title] as IconType);
                });
              }
            });
            break;

          case 'io5':
            import(`react-icons/io5`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'md':
            import(`react-icons/md`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'ti':
            import(`react-icons/ti`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'go':
            import(`react-icons/go`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'fi':
            import(`react-icons/fi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'lu':
            import(`react-icons/lu`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'gi':
            import(`react-icons/gi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'wi':
            import(`react-icons/wi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'di':
            import(`react-icons/di`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'ai':
            import(`react-icons/ai`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'bs':
            import(`react-icons/bs`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'ri':
            import(`react-icons/ri`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'fc':
            import(`react-icons/fc`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'gr':
            import(`react-icons/gr`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'hi':
            import(`react-icons/hi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'hi2':
            import(`react-icons/hi2`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'si':
            import(`react-icons/si`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'sl':
            import(`react-icons/sl`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'im':
            import(`react-icons/im`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'bi':
            import(`react-icons/bi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'cg':
            import(`react-icons/cg`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'vsc':
            import(`react-icons/vsc`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'tb':
            import(`react-icons/tb`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'tfi':
            import(`react-icons/tfi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'rx':
            import(`react-icons/rx`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'pi':
            import(`react-icons/pi`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          case 'lia':
            import(`react-icons/lia`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;

          default:
            import(`react-icons/ai`).then((module) => {
              setIconComponent(module[title] as IconType);
            });
            break;
        }
      }
    }
  }, [title]);

  return (IconComponent || null) as unknown as ReactElement;
};

export default Icon;
