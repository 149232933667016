import styled, { css } from 'styled-components';

interface ContainerProps {
  padding?: string;
  height?: string;
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ height }) => height || 'calc(100% - 32px)'};

  + div {
    margin-top: 8px;
  }

  textarea {
    padding: ${({ padding }) => padding || '5px'};
    flex: 1;
    background: transparent;
    border: 0;
    color: #777777;
    transition-duration: 0.2s;
    height: 100%;
    resize: none;
    outline: 0;

    ::placeholder {
      color: #a3a3a3;
    }
  }

  svg {
    margin-right: 16px;
    color: #777777;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff1a50 !important;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #777777;
      border-color: #f15a29 !important;
    `}
`;
