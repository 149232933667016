import styled from 'styled-components';

interface IContainer {
  hasError?: boolean;
}

interface IPreview {
  src: string;
  hasError?: boolean;
}

interface IPlaceholder {
  hasError?: boolean;
}

export const Container = styled.label<IContainer>`
  display: block;
  width: 100%;
  height: ${(props) => (props.hasError ? 300 : 250)}px;
  border-radius: 16px;
  overflow: hidden;
`;

export const Preview = styled.div<IPreview>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: ${(props) => (props.hasError ? 'calc(100% - 50px)' : '100%')};
`;

export const Placeholder = styled.div<IPlaceholder>`
  border-radius: 16px;
  border: 2px dashed ${(props) => (props.hasError ? '#FF1A50' : '#e4e4e4')};
  background: #f9f9f9;
  width: 100%;
  height: ${(props) => (props.hasError ? 'calc(100% - 50px)' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      color: #a3a3a3;
      text-align: center;
    }
  }
`;
