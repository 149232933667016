import styled from 'styled-components';

export const Container = styled.div`
  min-height: calc(100vh - 110px);

  .logo img {
    width: 42px;
    height: 42px;
  }

  .box {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;

    h1 {
      color: #202020;
    }

    > p {
      width: 55%;
    }

    form.white-box {
      border-radius: 21px;
      border: 1px solid #d7d7d7;
      background: #fff;

      .form-box {
        width: 80%;
        margin: 0 auto;

        .input-file {
          position: relative;

          .error-box {
            position: absolute;
            bottom: -30px;

            .error {
              font-size: 12px;
            }
          }
        }

        .input-photo {
          height: 260px;
        }

        .sub-photos {
          height: 166px;
        }

        .small-input-file {
          position: relative;

          .error-box {
            position: absolute;
            bottom: -40px;
            height: 36px;

            .error {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn-submit {
      background: #ad2d03;
      color: #fff;
    }
  }

  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 70px);

    .logo img {
      width: 63px;
      height: 63px;
    }

    .box {
      form.white-box {
        .form-box {
          width: 90%;

          .input-file {
            .error-box {
              .error {
                font-size: 90%;
              }
            }
          }

          .small-input-file {
            .error-box {
              bottom: -45px;
            }
          }
        }
      }
    }
  }
`;

export const StepsButton = styled.button`
  border: none;
  background-color: transparent;
`;
