/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Avatar, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';

import avatar from '~/assets/defaults/avatar-default.svg';

interface INoticeResponse {
  id: number;
  title: string;
  published: boolean;
  nursery: {
    name: string;
  };
}

interface INoticeData {
  data: INoticeResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface INotice {
  id: string;
  notice_id: number;
  title: string;
  published: boolean;
  nursery: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Notices: React.FC = () => {
  const [notices, setNotices] = useState<INotice[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noticeSelected, setNoticeSelected] = useState({} as INotice);

  const loadNotices = useCallback(async (pageData, search = '') => {
    const response = await api.get<INoticeData>('notices', {
      params: { page: pageData, search },
    });

    const data = response.data.data.map<INotice>((notice, index) => {
      return {
        id: (pageData * index + 1).toString().padStart(5, '0'),
        notice_id: notice.id,
        title: notice.title,
        published: notice.published,
        nursery: notice.nursery.name,
      };
    });

    setNotices(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadNotices(page).finally(() => setLoading(false));
  }, [loadNotices, page]);

  const handleClickOutside = useCallback((event) => {
    const swalElements = document.getElementsByClassName(
      'swal2-container'
    ) as unknown as HTMLElement[];
    const btnElements = document.getElementsByClassName(
      'btn-option'
    ) as unknown as HTMLElement[];
    const elements = document.getElementsByClassName(
      'options'
    ) as unknown as HTMLElement[];
    let close = true;
    for (const element of elements) {
      for (const btnElement of btnElements) {
        if (
          btnElement.contains(event.target) ||
          element.contains(event.target)
        ) {
          close = false;
        }

        if (swalElements.length > 0) {
          for (const swalElement of swalElements) {
            if (swalElement.contains(event.target)) {
              close = false;
            }
          }
        }
      }
    }

    if (close) {
      setNoticeSelected({} as INotice);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickDots = useCallback((notice: INotice) => {
    setNoticeSelected((state) =>
      state.id === notice.id ? ({} as INotice) : notice
    );
  }, []);

  const handleClickDeleteNotice = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar essa notícia?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#f15a29',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`notices/${noticeSelected.notice_id}`);

          loadNotices(page);
          setNoticeSelected({} as INotice);

          Toast.fire({
            icon: 'success',
            title: 'Notícia deletada!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [loadNotices, noticeSelected.notice_id, page]);

  const handleChangePublished = useCallback(
    async (checked, notice) => {
      const newNotices = notices.slice();
      const noticeIndex = newNotices.findIndex(
        (noticeData) => notice.id === noticeData.id
      );
      if (noticeIndex >= 0) {
        newNotices[noticeIndex].published = checked;
      }

      setNotices(newNotices);
    },
    [notices]
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Titulo',
        selector: 'title',
      },
      {
        name: 'Maternal',
        selector: 'nursery',
      },
      {
        name: 'Publicado',
        selector: 'published',
        cell: (row: INotice) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              onChange={(e) => handleChangePublished(e.target.checked, row)}
              checked={row.published}
            />
          </div>
        ),
      },
      {
        name: '',
        selector: 'id',
        cell: (row: INotice) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent btn-option"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options className="options" active={noticeSelected.id === row.id}>
              <Link to={`${process.env.PUBLIC_URL}/noticias/${row.notice_id}`}>
                Editar notícia
              </Link>
              <button type="button" onClick={handleClickDeleteNotice}>
                Deletar notícia
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleChangePublished,
      handleClickDeleteNotice,
      handleClickDots,
      noticeSelected.id,
    ]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadNotices(1, value);
    },
    [loadNotices]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center px-4">
              <h1 className="text-secondary">Notícias</h1>
              <div className="d-flex align-items-center">
                <Search onSearch={handleSearch} className="search me-3" />
                <Link
                  to={`${process.env.PUBLIC_URL}/noticias/nova`}
                  className="btn btn-primary rounded-pill py-2 px-4 d-flex aling-items-center ms-3"
                >
                  <FiPlus size={22} color="#fff" className="me-2" /> Nova
                </Link>
              </div>
            </div>
            <Table
              columns={columns}
              data={notices}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-notices mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default Notices;
