import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { darken } from 'polished';

export const Container = styled.div``;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;

      .btn {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }

      .btn-refuse {
        background-color: #ff1a50;

        :hover {
          background-color: ${darken(0.05, '#FF1A50')};
        }
      }

      .btn-accept {
        background-color: #00c968;

        :hover {
          background-color: ${darken(0.05, '#00C968')};
        }
      }

      .refused {
        background-color: #ff1a50;
        color: #fff;
      }
    }

    .input-file {
      position: relative;

      p {
        color: #777;
      }

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }

    .input-checkbox {
      justify-content: flex-start;
      flex-direction: column;

      label + label {
        margin-top: 10px;
        margin-left: 0;
      }
    }

    .btn-add-input {
      color: #777;
    }
  }
`;
