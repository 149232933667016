import React from 'react';
import {
  Document,
  Image,
  Page,
  Text,
  View,
  StyleSheet,
} from '@react-pdf/renderer';

import logo from '~/assets/logos/logo.png';

interface IProfileDocument {
  type: 'Fornecedor' | 'Prestador';
  date: string;
  companyName: string;
  email: string;
  phone: string;
  cnpj: string;
  cnae?: string;
  presidentName: string;
  cpf?: string;
  rg?: string;
  address?: string;
}

const styles = StyleSheet.create({
  page: {
    padding: '16px',
  },
  box: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: '150px',
    marginBottom: '16px',
  },
  companyName: {
    fontSize: '16px',
    fontFamily: 'Helvetica-Bold',
    marginTop: '10px',
    marginBottom: '10px',
  },
  companyData: {
    fontSize: '16px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '15px',
  },
  text: {
    fontSize: '14px',
    marginBottom: '5px',
    fontFamily: 'Helvetica',
  },
  address: {
    fontSize: '14px',
    marginTop: '5px',
    lineHeight: 1.5,
    fontFamily: 'Helvetica',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  boxTitle: {
    alignItems: 'flex-end',
  },
  title: {
    fontSize: '16px',
    textAlign: 'right',
    marginBottom: '5px',
    fontFamily: 'Helvetica-Bold',
  },
  date: {
    fontSize: '14px',
    textAlign: 'right',
    fontFamily: 'Helvetica',
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#8e8e8e',
    marginVertical: '16px',
  },
});

const ProfileDocument: React.FC<IProfileDocument> = ({
  type,
  date,
  companyName,
  email,
  phone,
  address,
  cnpj,
  cnae,
  presidentName,
  cpf,
  rg,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View>
          <View style={styles.box}>
            <View>
              <Image source={logo} style={styles.logo} />
            </View>
            <View style={styles.boxTitle}>
              <Text style={styles.title}>Ficha do {type}</Text>
              <Text style={styles.date}>Data de Emissão: {date}</Text>
            </View>
          </View>
          <Text style={styles.companyName}>{companyName}</Text>
          <View>
            <View style={styles.box}>
              <Text style={styles.text}>
                <Text style={styles.bold}>E-mail:</Text> {email}
              </Text>
              <Text style={{ ...styles.text }}>
                <Text style={styles.bold}>Telefone:</Text> {phone}
              </Text>
            </View>
            <Text style={styles.address}>
              <Text style={styles.bold}>Endereço:</Text> {address || '-'}
            </Text>
          </View>
        </View>
        <View style={styles.line} />
        <View>
          <Text style={styles.companyData}>Dados complementares</Text>
          <Text style={styles.text}>
            <Text style={styles.bold}>CNPJ:</Text> {cnpj}
          </Text>
          <Text style={{ ...styles.text, marginTop: '5px' }}>
            <Text style={styles.bold}>CNAE:</Text> {cnae || '-'}
          </Text>
          <Text style={{ ...styles.text, marginTop: '5px' }}>
            <Text style={styles.bold}>Representante legal:</Text>{' '}
            {presidentName}
          </Text>
          <View style={{ ...styles.box, marginTop: '5px' }}>
            <Text style={styles.text}>
              <Text style={styles.bold}>CPF:</Text> {cpf || '-'}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>RG:</Text> {rg || '-'}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ProfileDocument;
