import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { CgSoftwareUpload } from 'react-icons/cg';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container, InputPhotos, Img } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';

import api from '~/services/api';
import Toast from '~/utils/toast';

interface IParams {
  galleryId: string;
}

interface IPhoto {
  id: number;
  archive_url: string;
}

interface IGallery {
  id: number;
  nursery_id: number;
  title: string;
  year: number;
  photos: IPhoto[];
}

interface INursery {
  id: number;
  name: string;
}

interface IFormData {
  nursery: string;
  title: string;
  year: string;
}

const GalleryUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [gallery, setGallery] = useState({} as IFormData);
  const [photos, setPhotos] = useState<File[]>([]);
  const [oldPhotosData, setOldPhotosData] = useState<IPhoto[]>([]);
  const [photosData, setPhotosData] = useState<IPhoto[]>([]);
  const [photosPreview, setPhotosPreview] = useState<string[]>([]);
  const [photoError, setPhotoError] = useState('');

  useEffect(() => {
    api.get<IGallery>(`galleries/${params.galleryId}`).then((response) => {
      setGallery({
        title: response.data.title,
        year: response.data.year.toString(),
        nursery: response.data.nursery_id.toString(),
      });
      setOldPhotosData(response.data.photos);
      setPhotosData(response.data.photos);
      setPhotosPreview(response.data.photos.map((photo) => photo.archive_url));
    });
  }, [params.galleryId]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          id: nursery.id,
          value: nursery.name,
          selected: gallery.nursery === nursery.id.toString(),
          notSelectable: false,
        }));
        data.unshift({
          id: '',
          value: 'selecionar',
          selected: false,
          notSelectable: true,
        });
        setNurseries(data);
      });
  }, [gallery.nursery]);

  const years = useMemo<IOption[]>(() => {
    const year = new Date().getFullYear();
    return [
      {
        id: '',
        value: 'selecionar',
        selected: false,
        notSelectable: true,
      },
      ...Array.from({ length: 101 }, (_, index) => ({
        id: year - 100 + index,
        value: `${year - 100 + index}`,
        selected: gallery.year === (year - 100 + index).toString(),
        notSelectable: false,
      })).reverse(),
    ];
  }, [gallery.year]);

  const handleChangePhotos = useCallback(
    (files: File[]) => {
      console.log(files);
      setPhotos(files);
      const data = files.map((file) => URL.createObjectURL(file));
      const oldData = photosData.map((photo) => photo.archive_url);
      setPhotosPreview([...oldData, ...data]);
    },
    [photosData]
  );

  const handleClickRemovePhoto = useCallback(
    (indexPhoto) => {
      setPhotosData((state) =>
        state.filter(
          (photoData) => photoData.archive_url !== photosPreview[indexPhoto]
        )
      );
      setPhotos((state) => state.filter((_, index) => index !== indexPhoto));
      setPhotosPreview((state) =>
        state.filter((_, index) => index !== indexPhoto)
      );
    },
    [photosPreview]
  );

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setPhotoError('');

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          year: Yup.string().required('O ano é obrigatório'),
          nursery: Yup.string().required('O maternal é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          nursery_id: data.nursery,
          title: data.title,
          year: data.year,
        };

        const response = await api.put(
          `galleries/${params.galleryId}`,
          formData
        );

        const deletedPhotos = oldPhotosData.filter(
          (oldPhoto) => !photosData.find((photo) => oldPhoto.id === photo.id)
        );

        if (deletedPhotos.length > 0) {
          await new Promise<void>((resolve) => {
            deletedPhotos.forEach(async (photo, index) => {
              await api.delete(`archives/${photo.id}`);

              if (deletedPhotos.length === index + 1) {
                resolve();
              }
            });
          });
        }

        if (photos.length > 0) {
          await new Promise<void>((resolve) => {
            photos.forEach(async (photo, index) => {
              const photoFormData = new FormData();
              photoFormData.append('photo', photo as File);
              photoFormData.append('gallery_id', response.data.id);
              await api.post('archives', photoFormData);

              if (photos.length === index + 1) {
                resolve();
              }
            });
          });
        }

        Toast.fire({
          icon: 'success',
          title: 'Galeria cadastrada com sucesso!',
        });
        history.push(`${process.env.PUBLIC_URL}/galerias`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (errors.photo) {
            setPhotoError(errors.photo);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [history, oldPhotosData, params.galleryId, photos, photosData]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row align-items-center px-3 px-sm-0">
          <div className="col-6">
            <h1 className="text-secondary">Editar galeria</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="box py-3 px-3 px-lg-5">
              <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="white-box w-100 py-5 mt-5"
                  initialData={gallery}
                >
                  <div className="form-box">
                    <div className="row">
                      <div className="col-lg-9">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Título
                          </span>
                          <Input name="title" placeholder="Digite aqui" />
                        </label>
                      </div>
                      <div className="col-lg-3">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Ano
                          </span>
                          <Select
                            name="year"
                            placeholder="Digite aqui"
                            onlySelect
                            options={years}
                          />
                        </label>
                      </div>
                      <div className="col-12">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Maternal
                          </span>
                          <Select
                            name="nursery"
                            placeholder="Digite aqui"
                            onlySelect
                            options={nurseries}
                          />
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <div className="mb-3">
                            <label className="w-100">
                              <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                Fotos
                              </span>
                            </label>
                            <div>
                              <InputPhotos
                                name="photos"
                                onChange={(files) => handleChangePhotos(files)}
                                multiple
                                height={photosPreview.length > 0 ? 46 : 250}
                                hasPhotos={photosPreview.length > 0}
                                accept="image/jpeg, image/png"
                                filesValue={photos}
                              >
                                <div
                                  className={`d-flex align-items-center box-label ${
                                    photosPreview.length === 0
                                      ? 'justify-content-center'
                                      : ''
                                  }`}
                                >
                                  <CgSoftwareUpload
                                    size={photosPreview.length === 0 ? 40 : 24}
                                    color={
                                      photosPreview.length === 0
                                        ? '#a3a3a3'
                                        : '#fff'
                                    }
                                    className="ms-lg-3 me-lg-2"
                                  />
                                  <p
                                    className={`mb-0 ${
                                      photosPreview.length === 0
                                        ? 'text-lg-center'
                                        : 'text-start text-white'
                                    }`}
                                  >
                                    {photosPreview.length > 0
                                      ? 'Adicionar ou arrastar aqui'
                                      : 'Clique aqui ou arraste e solte uma imagem'}
                                  </p>
                                </div>
                              </InputPhotos>
                              {photosPreview.length > 0 && (
                                <div className="photos-preview p-3">
                                  <div className="row">
                                    {photosPreview.map((photo, index) => (
                                      <div className="col-lg-3 mb-3">
                                        <Img
                                          src={photo}
                                          className="d-flex align-items-start justify-content-end p-3"
                                        >
                                          <button
                                            type="button"
                                            className="border-0 bg-transparent"
                                            onClick={() =>
                                              handleClickRemovePhoto(index)
                                            }
                                          >
                                            <AiOutlineClose
                                              color="#fff"
                                              size={24}
                                            />
                                          </button>
                                        </Img>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-lg-11 px-4">
                      <div className="w-100 mx-auto d-flex justify-content-end mt-3 mt-lg-5">
                        <button
                          type="submit"
                          className="btn btn-primary rounded-pill py-2 px-4 w-100 w-lg-auto"
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GalleryUpdate;
