import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RiArrowDownSLine, RiQuestionLine } from 'react-icons/ri';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import { MdLogin } from 'react-icons/md';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Timeline, StepsButton } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputFile from '~/components/InputFile';

import logo from '~/assets/logos/logo.svg';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

interface ICompanyResponse {
  razao_social: string;
  qualificacao_do_responsavel: {
    id: number;
  };
  socios: {
    nome: string;
    qualificacao_socio: {
      id: number;
    };
  }[];
  estabelecimento: {
    cep: string;
    tipo_logradouro: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
    email: string;
    ddd1: string;
    telefone1: string;
    atividade_principal: {
      subclasse: string;
      descricao: string;
    };
    estado: {
      nome: string;
    };
    cidade: {
      nome: string;
    };
  };
}

interface IParams {
  step: string;
}

interface IFormData {
  cnpj: string;
  companyName: string;
  cnae: string;
  presidentName: string;
  rg: string;
  cpf: string;
  zipcode: string;
  street: string;
  number: string;
  state: string;
  city: string;
  neighborhood: string;
  complement: string;
  email: string;
  phone: string;
  password: string;
}

const SupplierRegister: React.FC = () => {
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [cnpj, setCnpj] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnae, setCnae] = useState('');
  const [presidentName, setPresidentName] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [complement, setComplement] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cnpjCard, setCnpjCard] = useState<File | undefined>();
  const [cnpjCardError, setCnpjCardError] = useState('');
  const [socialContract, setSocialContract] = useState<File | undefined>();
  const [socialContractError, setSocialContractError] = useState('');
  const [mei, setMei] = useState<File | undefined>();
  const [meiError, setMeiError] = useState('');
  const [fgtsNegative, setFgtsNegative] = useState<File | undefined>();
  const [fgtsNegativeError, setFgtsNegativeError] = useState('');
  const [federal, setFederal] = useState<File | undefined>();
  const [federalError, setFederalError] = useState('');
  const [labor, setLabor] = useState<File | undefined>();
  const [laborError, setLaborError] = useState('');
  const [fgtsProof, setFgtsProof] = useState<File | undefined>();
  const [fgtsProofError, setFgtsProofError] = useState('');
  const [stepsError, setStepError] = useState({
    company: false,
    address: false,
    contact: false,
    documents: false,
  });
  const [step, setStep] = useState({
    number: 1,
    name: 'Empresa',
  });
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (params.step === 'empresa' && stepsError.company) {
      formRef.current?.submitForm();
    }

    if (params.step === 'endereco' && stepsError.address) {
      formRef.current?.submitForm();
    }

    if (params.step === 'contato' && stepsError.contact) {
      formRef.current?.submitForm();
    }

    if (params.step === 'documentos' && stepsError.documents) {
      formRef.current?.submitForm();
    }
  }, [
    params.step,
    stepsError.address,
    stepsError.company,
    stepsError.contact,
    stepsError.documents,
  ]);

  useEffect(() => {
    setStep(() => {
      if (params.step === 'empresa') {
        return {
          number: 1,
          name: 'Empresa',
        };
      }
      if (params.step === 'endereco') {
        return {
          number: 2,
          name: 'Endereço',
        };
      }
      if (params.step === 'contato') {
        return {
          number: 3,
          name: 'Contato',
        };
      }

      return {
        number: 4,
        name: 'Documentos',
      };
    });
    setOpened(false);
  }, [params.step]);

  const handleChangeCnpj = useCallback(async (e) => {
    try {
      if (e.target.value.length === 18) {
        const cnpjDoc = e.target.value
          .replaceAll('.', '')
          .replace('/', '')
          .replace('-', '');
        const response = await axios.get<ICompanyResponse>(
          `https://publica.cnpj.ws/cnpj/${cnpjDoc}`
        );
        setCompanyName(response.data.razao_social);
        setCnae(
          `${response.data.estabelecimento.atividade_principal.subclasse} ${response.data.estabelecimento.atividade_principal.descricao}`
        );
        const socioData = response.data.socios.find(
          (socio) =>
            socio.qualificacao_socio.id ===
            response.data.qualificacao_do_responsavel.id
        );
        setPresidentName(socioData?.nome || '');
        setZipcode(response.data.estabelecimento.cep);
        setStreet(
          `${response.data.estabelecimento.tipo_logradouro} ${response.data.estabelecimento.logradouro}`
        );
        setNumber(response.data.estabelecimento.numero);
        setNeighborhood(response.data.estabelecimento.bairro);
        setCity(response.data.estabelecimento.cidade.nome);
        setState(response.data.estabelecimento.estado.nome);
        setComplement(response.data.estabelecimento.complemento);
        setEmail(response.data.estabelecimento.email);
        setPhone(
          `${response.data.estabelecimento.ddd1}${response.data.estabelecimento.telefone1}`
        );
      }

      setCnpj(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    try {
      if (e.target.value.length === 9) {
        const zipcodeDoc = e.target.value.replace('-', '');
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipcodeDoc}/json/`
        );
        setStreet(response.data.logradouro);
        setNeighborhood(response.data.bairro);
        setCity(response.data.localidade);
        setState(response.data.uf);
        setComplement(response.data.complemento);
      }

      setZipcode(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeCnpjCard = useCallback((files: File[]) => {
    setCnpjCard(files[0]);
  }, []);

  const handleChangeSocialContract = useCallback((files: File[]) => {
    setSocialContract(files[0]);
  }, []);

  const handleChangeMei = useCallback((files: File[]) => {
    setMei(files[0]);
  }, []);

  const handleChangeFgtsNegative = useCallback((files: File[]) => {
    setFgtsNegative(files[0]);
  }, []);

  const handleChangeFederal = useCallback((files: File[]) => {
    setFederal(files[0]);
  }, []);

  const handleChangeLabor = useCallback((files: File[]) => {
    setLabor(files[0]);
  }, []);

  const handleChangeFgtsProof = useCallback((files: File[]) => {
    setFgtsProof(files[0]);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      let hasError = false;
      if (params.step === 'empresa' && stepsError.company) {
        hasError = true;
      }
      if (params.step === 'endereco' && stepsError.address) {
        hasError = true;
      }
      if (params.step === 'contato' && stepsError.contact) {
        hasError = true;
      }
      if (params.step === 'documentos' && stepsError.documents) {
        hasError = true;
      }
      try {
        formRef.current?.setErrors({});
        setCnpjCardError('');
        setSocialContractError('');
        setMeiError('');
        setFgtsNegativeError('');
        setFederalError('');
        setLaborError('');
        setFgtsProofError('');

        if (params.step !== 'documentos') {
          let path = '';
          let nextStep = true;
          switch (params.step) {
            case 'empresa':
              path = 'endereco';
              nextStep = !stepsError.company;
              break;
            case 'endereco':
              path = 'contato';
              nextStep = !stepsError.address;
              break;
            case 'contato':
              path = 'documentos';
              nextStep = !stepsError.contact;
              break;

            default:
              break;
          }
          if (nextStep) {
            history.push(
              `${process.env.PUBLIC_URL}/fornecedor/cadastro/${path}`
            );
          }
        }

        let schema = Yup.object().shape({});
        if (params.step === 'empresa') {
          setStepError((oldState) => ({ ...oldState, company: false }));
          schema = Yup.object().shape({
            cnpj: Yup.string().required('O CNPJ é obrigatório'),
            companyName: Yup.string().required('A razão social é obrigatória'),
            cnae: Yup.string().required('O CNAE é obrigatório'),
            presidentName: Yup.string().required(
              'O representante legal é obrigatório'
            ),
            rg: Yup.string().required('O RG é obrigatório'),
            cpf: Yup.string().required('O CPF é obrigatório'),
          });
        }

        if (params.step === 'endereco') {
          setStepError((oldState) => ({ ...oldState, address: false }));
          schema = Yup.object().shape({
            zipcode: Yup.string().required('O CEP é obrigatório'),
            street: Yup.string().required('A rua é obrigatória'),
            number: Yup.string().required('O número é obrigatório'),
            state: Yup.string().required('O estado é obrigatório'),
            city: Yup.string().required('A cidade é obrigatória'),
            neighborhood: Yup.string().required('O bairro é obrigatório'),
            complement: Yup.string(),
          });
        }

        if (params.step === 'contato') {
          setStepError((oldState) => ({ ...oldState, contact: false }));
          schema = Yup.object().shape({
            email: Yup.string().required('O e-mail é obrigatório'),
            phone: Yup.string().required('A telefone é obrigatória'),
            password: Yup.string().required('A senha é obrigatória'),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'As senhas devem ser iguais'
            ),
          });
        }

        if (params.step === 'documentos') {
          setStepError((oldState) => ({ ...oldState, documents: false }));
          schema = Yup.object().shape({
            cnpjCard: Yup.string().when('$cnpjCardCheck', {
              is: (cnpjCardCheck: boolean) => !cnpjCardCheck,
              then: Yup.string().required('O cartão CNPJ é obrigatório'),
              otherwise: Yup.string(),
            }),
            socialContract: Yup.string().when('$socialContractCheck', {
              is: (socialContractCheck: boolean) => !socialContractCheck,
              then: Yup.string().required('O contrato social é obrigatório'),
              otherwise: Yup.string(),
            }),
            // mei: Yup.string().when('$meiCheck', {
            //   is: (meiCheck: boolean) => !meiCheck,
            //   then: Yup.string().required('O cadastro de micro empreendedor é obrigatório'),
            //   otherwise: Yup.string(),
            // }),
            fgtsNegative: Yup.string().when('$fgtsNegativeCheck', {
              is: (fgtsNegativeCheck: boolean) => !fgtsNegativeCheck,
              then: Yup.string().required(
                'A certidão negativa FGTS é obrigatória'
              ),
              otherwise: Yup.string(),
            }),
            federal: Yup.string().when('$federalCheck', {
              is: (federalCheck: boolean) => !federalCheck,
              then: Yup.string().required('A certidão federal é obrigatória'),
              otherwise: Yup.string(),
            }),
            labor: Yup.string().when('$laborCheck', {
              is: (laborCheck: boolean) => !laborCheck,
              then: Yup.string().required(
                'A certidão trabalhista é obrigatória'
              ),
              otherwise: Yup.string(),
            }),
            // fgtsProof: Yup.string().when('$fgtsProofCheck', {
            //   is: (fgtsProofCheck: boolean) => !fgtsProofCheck,
            //   then: Yup.string().required('O Cartão CNPJ é obrigatório'),
            //   otherwise: Yup.string(),
            // }),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
          context: {
            cnpjCard: !cnpjCard,
            socialContract: !socialContract,
            mei: !mei,
            fgtsNegative: !fgtsNegative,
            federal: !federal,
            labor: !labor,
            fgtsProof: !fgtsProof,
          },
        });

        if (params.step === 'documentos') {
          if (stepsError.company) {
            history.push(
              `${process.env.PUBLIC_URL}/fornecedor/cadastro/empresa`
            );
            return;
          }
          if (stepsError.address) {
            history.push(
              `${process.env.PUBLIC_URL}/fornecedor/cadastro/endereço`
            );
            return;
          }
          if (stepsError.contact) {
            history.push(
              `${process.env.PUBLIC_URL}/fornecedor/cadastro/contato`
            );
            return;
          }

          const formData = {
            cnpj: data.cnpj,
            company: data.companyName,
            cnae: data.cnae,
            president_name: data.presidentName,
            rg: data.rg,
            cpf: data.cnpj,
            email: data.email,
            phone: data.phone,
            password: data.password,
          };

          const response = await api.post('suppliers', formData);

          const addressFormData = {
            supplier_id: response.data.id,
            street: data.street,
            number: data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            zip_code: data.zipcode,
            complement: data.complement,
          };

          await api.post('addresses', addressFormData);

          const documents = [
            cnpjCard,
            socialContract,
            mei,
            fgtsNegative,
            federal,
            labor,
            fgtsProof,
          ];

          const documentsType = [
            'cnpjCard',
            'socialContract',
            'mei',
            'fgtsNegative',
            'federal',
            'labor',
            'fgtsProof',
          ];

          if (documents.length > 0) {
            await new Promise<void>((resolve) => {
              documents.forEach(async (document, index) => {
                if (document) {
                  const documentFormData = new FormData();
                  documentFormData.append('supplier_id', response.data.id);
                  documentFormData.append('document', document);
                  documentFormData.append('type', documentsType[index]);
                  await api.post('archives', documentFormData);
                }

                if (documents.length === index + 1) {
                  resolve();
                }
              });
            });
          }

          signIn({ email: data.email, password: data.password });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (!hasError) {
            if (params.step === 'empresa') {
              setStepError((oldState) => ({ ...oldState, company: true }));
            }
            if (params.step === 'endereco') {
              setStepError((oldState) => ({ ...oldState, address: true }));
            }
            if (params.step === 'contato') {
              setStepError((oldState) => ({ ...oldState, contact: true }));
            }
            if (params.step === 'documentos') {
              setStepError((oldState) => ({ ...oldState, documents: true }));
            }
          }

          if (errors.cnpjCard) {
            setCnpjCardError(errors.cnpjCard);
          }

          if (errors.socialContract) {
            setSocialContractError(errors.socialContract);
          }

          if (errors.mei) {
            setMeiError(errors.mei);
          }

          if (errors.fgtsNegative) {
            setFgtsNegativeError(errors.fgtsNegative);
          }

          if (errors.federal) {
            setFederalError(errors.federal);
          }

          if (errors.labor) {
            setLaborError(errors.labor);
          }

          if (errors.fgtsProof) {
            setFgtsProofError(errors.fgtsProof);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      cnpjCard,
      federal,
      fgtsNegative,
      fgtsProof,
      history,
      labor,
      mei,
      params.step,
      signIn,
      socialContract,
      stepsError.address,
      stepsError.company,
      stepsError.contact,
      stepsError.documents,
    ]
  );

  const handleClickStepsButton = useCallback(() => {
    setOpened((oldState) => !oldState);
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row align-items-center px-3 px-sm-0">
          <div className="col-6">
            <Link
              to={`${process.env.PUBLIC_URL}/fornecedor/login`}
              className="d-flex align-items-center logo"
            >
              <img src={logo} alt="logo" className="me-2" />
              <span className="text-secondary text-start d-block small h5-lg mb-0 fw-bold">
                INSTITUTO <br />
                <span className="d-block h4 h2-lg mb-0 fw-bold">BARUERI</span>
              </span>
            </Link>
          </div>
          <div className="col-6">
            <p className="text-end mb-0">
              <span className="d-none d-lg-block">Já possui cadastro? </span>
              <Link
                to={`${process.env.PUBLIC_URL}/fornecedor/login`}
                className="fw-bold text-primary"
              >
                Entrar
                <MdLogin size={24} color="#F15A29" className="ms-2" />
              </Link>
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="box py-5 px-3 px-lg-5">
              {!params.step && (
                <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                  <h1 className="h4 h3-lg fw-normal mb-0 text-center text-lg-start">
                    Cadastro do <b className="fw-semibold">fornecedor</b>
                  </h1>
                  <p className="text-center h5-lg fw-normal mt-4 mb-5">
                    Olá fornecedor, para prosseguir, cadastre-se. Caso tenha
                    alguma dúvida, os ícones com interrogação te ajudarão (
                    <RiQuestionLine size={20} color="#707070" />)
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/fornecedor/cadastro/empresa`}
                    className="btn btn-submit rounded-pill py-3 px-5"
                  >
                    Ir para o cadastro
                  </Link>
                </div>
              )}
              {!!params.step && (
                <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                  <h1 className="h6 fw-normal mb-3 text-secondary">
                    Cadastro do fornecedor
                  </h1>
                  <h2 className="h5 h4-lg text-secondary text-center text-lg-start">
                    Dados para cadastro pessoa juridica
                  </h2>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className="white-box w-100 py-5 mt-5"
                  >
                    <Timeline
                      opened={opened}
                      className="d-lg-flex justify-content-between mb-5"
                    >
                      <StepsButton
                        type="button"
                        className="d-flex d-md-none align-items-center w-100"
                        onClick={handleClickStepsButton}
                      >
                        <div className="step me-2">
                          <div className="active">{step.number}</div>
                        </div>
                        <div className="d-flex flex-column align-items-start">
                          <small>0{step.number} de 04</small>
                          <p className="mb-0 fw-semibold text-secondary">
                            {step.name}
                          </p>
                        </div>
                        <RiArrowDownSLine
                          size={24}
                          color="#202020"
                          className="ms-auto"
                        />
                      </StepsButton>
                      <div className="d-md-flex justify-content-between w-100 group-steps">
                        <hr className="d-block d-md-none" />
                        <Link
                          to={`${process.env.PUBLIC_URL}/fornecedor/cadastro/empresa`}
                          className="d-flex flex-md-column align-items-center justify-content-lg-center"
                        >
                          <div className="step">
                            <div
                              className={
                                params.step === 'empresa' ? 'active' : ''
                              }
                            >
                              1
                            </div>
                          </div>
                          <p className="text-secondary mb-0">Empresa</p>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/fornecedor/cadastro/endereco`}
                          className="d-flex flex-md-column align-items-center justify-content-lg-center"
                        >
                          <div className="step">
                            <div
                              className={
                                params.step === 'endereco' ? 'active' : ''
                              }
                            >
                              2
                            </div>
                          </div>
                          <p className="text-secondary mb-0">Endereço</p>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/fornecedor/cadastro/contato`}
                          className="d-flex flex-md-column align-items-center justify-content-lg-center"
                        >
                          <div className="step">
                            <div
                              className={
                                params.step === 'contato' ? 'active' : ''
                              }
                            >
                              3
                            </div>
                          </div>
                          <p className="text-secondary mb-0">Contato</p>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/fornecedor/cadastro/documentos`}
                          className="d-flex flex-md-column align-items-center justify-content-lg-center"
                        >
                          <div className="step">
                            <div
                              className={
                                params.step === 'documentos' ? 'active' : ''
                              }
                            >
                              4
                            </div>
                          </div>
                          <p className="text-secondary mb-0">Documentos</p>
                        </Link>
                      </div>
                    </Timeline>

                    <div
                      className={`form-box ${
                        params.step === 'empresa' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              CNPJ
                            </span>
                            <InputMask
                              kind="cnpj"
                              name="cnpj"
                              placeholder="Digite aqui"
                              onChange={handleChangeCnpj}
                              value={cnpj}
                            />
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Razão social
                            </span>
                            <Input
                              name="companyName"
                              placeholder="Digite aqui"
                              onChange={(e) => setCompanyName(e.target.value)}
                              value={companyName}
                            />
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              CNAE
                            </span>
                            <Input
                              name="cnae"
                              placeholder="Digite aqui"
                              onChange={(e) => setCnae(e.target.value)}
                              value={cnae}
                            />
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Representante legal
                            </span>
                            <Input
                              name="presidentName"
                              placeholder="Digite aqui"
                              onChange={(e) => setPresidentName(e.target.value)}
                              value={presidentName}
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              RG
                            </span>
                            <InputMask
                              kind="custom"
                              options={{
                                mask: '99.999.999-S',
                              }}
                              name="rg"
                              placeholder="Digite aqui"
                              onChange={(e) => setRg(e.target.value)}
                              value={rg}
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              CPF
                            </span>
                            <InputMask
                              kind="cpf"
                              name="cpf"
                              placeholder="Digite aqui"
                              onChange={(e) => setCpf(e.target.value)}
                              value={cpf}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`form-box ${
                        params.step === 'endereco' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <div className="row">
                        <div className="col-lg-3">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              CEP
                            </span>
                            <InputMask
                              kind="zip-code"
                              name="zipcode"
                              placeholder="Digite aqui"
                              onChange={handleChangeZipCode}
                              value={zipcode}
                            />
                          </label>
                        </div>
                        <div className="col-lg-9">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Rua
                            </span>
                            <Input
                              name="street"
                              placeholder="Digite aqui"
                              onChange={(e) => setStreet(e.target.value)}
                              value={street}
                            />
                          </label>
                        </div>
                        <div className="col-lg-3">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Número
                            </span>
                            <Input
                              name="number"
                              placeholder="Digite aqui"
                              onChange={(e) => setNumber(e.target.value)}
                              value={number}
                            />
                          </label>
                        </div>
                        <div className="col-lg-4">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Estado
                            </span>
                            <Input
                              name="state"
                              placeholder="Digite aqui"
                              onChange={(e) => setState(e.target.value)}
                              value={state}
                            />
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Cidade
                            </span>
                            <Input
                              name="city"
                              placeholder="Digite aqui"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Bairro
                            </span>
                            <Input
                              name="neighborhood"
                              placeholder="Digite aqui"
                              onChange={(e) => setNeighborhood(e.target.value)}
                              value={neighborhood}
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Complemento
                            </span>
                            <Input
                              name="complement"
                              placeholder="Digite aqui"
                              onChange={(e) => setComplement(e.target.value)}
                              value={complement}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`form-box ${
                        params.step === 'contato' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              E-mail
                            </span>
                            <Input
                              type="email"
                              name="email"
                              placeholder="Digite aqui"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </label>
                        </div>
                        <div className="col-12">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Telefone
                            </span>
                            <InputMask
                              kind="cel-phone"
                              name="phone"
                              placeholder="Digite aqui"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Digite uma senha
                            </span>
                            <Input
                              type="password"
                              name="password"
                              placeholder="Digite aqui"
                            />
                          </label>
                        </div>
                        <div className="col-lg-6">
                          <label className="mb-3 w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Confirmar senha
                            </span>
                            <Input
                              type="password"
                              name="confirmPassword"
                              placeholder="Digite aqui"
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`form-box ${
                        params.step === 'documentos' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <div className="row">
                        <div className="col-12 mb-3">
                          <label className="d-flex align-items-center w-100 mb-2">
                            <span className="text-secondary fw-medium me-1 small normal-sm">
                              Cartão CNPJ
                            </span>
                            <a href="/#">
                              <RiQuestionLine size={20} color="#707070" />
                            </a>
                          </label>
                          <InputFile
                            name="cnpjCard"
                            onChange={handleChangeCnpjCard}
                            className={`input-file px-2 px-lg-0 ${
                              cnpjCardError ? 'mb-3' : ''
                            }`}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label className="d-flex align-items-center w-100 mb-2">
                            <span className="text-secondary fw-medium me-1 small normal-sm">
                              Contrato social
                            </span>
                            <a href="/#">
                              <RiQuestionLine size={20} color="#707070" />
                            </a>
                          </label>
                          <InputFile
                            name="socialContract"
                            onChange={handleChangeSocialContract}
                            className={`input-file px-2 px-lg-0 ${
                              socialContractError ? 'mb-4 mb-lg-3' : ''
                            }`}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label className="d-flex align-items-center w-100 mb-2">
                            <span className="text-secondary fw-medium me-1 small normal-sm">
                              MEI - Cadastro de Micro Empreendedor
                            </span>
                            <a href="/#">
                              <RiQuestionLine size={20} color="#707070" />
                            </a>
                          </label>
                          <InputFile
                            name="mei"
                            onChange={handleChangeMei}
                            className={`input-file px-2 px-lg-0 ${
                              meiError ? 'mb-3' : ''
                            }`}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <h3 className="h4 text-secondary my-3">Certidões</h3>
                          <div className="row">
                            <div className="col-lg-4">
                              <label className="d-flex align-items-center w-100 mb-2">
                                <span className="text-secondary fw-medium me-1 small normal-sm">
                                  Negativa FGTS
                                </span>
                                <a href="/#">
                                  <RiQuestionLine size={20} color="#707070" />
                                </a>
                              </label>
                              <InputFile
                                name="fgtsNegative"
                                onChange={handleChangeFgtsNegative}
                                className={`small-input-file px-2 px-lg-0 ${
                                  fgtsNegativeError ? 'mb-5' : ''
                                }`}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label className="d-flex align-items-center w-100 mb-2">
                                <span className="text-secondary fw-medium me-1 small normal-sm">
                                  Federal
                                </span>
                                <a href="/#">
                                  <RiQuestionLine size={20} color="#707070" />
                                </a>
                              </label>
                              <InputFile
                                name="federal"
                                onChange={handleChangeFederal}
                                className={`small-input-file px-2 px-lg-0 ${
                                  federalError ? 'mb-5 mb-lg-4' : ''
                                }`}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label className="d-flex align-items-center w-100 mb-2">
                                <span className="text-secondary fw-medium me-1 small normal-sm">
                                  Trabalhista
                                </span>
                                <a href="/#">
                                  <RiQuestionLine size={20} color="#707070" />
                                </a>
                              </label>
                              <InputFile
                                name="labor"
                                onChange={handleChangeLabor}
                                className={`small-input-file px-2 px-lg-0 ${
                                  laborError ? 'mb-5 mb-lg-4' : ''
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-3">
                          <label className="d-flex align-items-center w-100 mb-2">
                            <span className="text-secondary fw-medium me-1 small normal-sm">
                              Comprovante de FGTS (Caso possua vinculo
                              empregatício)
                            </span>
                            <a href="/#">
                              <RiQuestionLine size={20} color="#707070" />
                            </a>
                          </label>
                          <InputFile
                            name="fgtsProof"
                            onChange={handleChangeFgtsProof}
                            className={`input-file px-2 px-lg-0 ${
                              fgtsProofError ? 'mb-3' : ''
                            }`}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-75 mx-auto d-flex justify-content-end mt-3 mt-lg-5">
                      <button
                        type="submit"
                        className="btn btn-submit rounded-pill py-2 px-4 w-100 w-lg-auto"
                      >
                        Continuar
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SupplierRegister;
