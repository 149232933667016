import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

import transparency from '~/assets/defaults/bg-transparency.png';

import bgMenu from '~/assets/defaults/bg-menu.svg';

interface IMenu {
  bgcolor: string;
  default?: boolean;
}

interface IBanner {
  color: string;
}

interface IBtnTitle {
  opened: boolean;
}

interface IMenusGroup {
  opened: boolean;
}

export const Container = styled.div`
  .btn-option {
    -webkit-text-fill-color: #4b4b4b;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: transparent;
    transition-duration: 0.3s;
    position: relative;

    ::after {
      content: '';
      position: absolute;
      width: 50px;
      height: 4px;
      border-radius: 5px;
      background-color: #f15a29;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition-duration: 0.3s;
    }
  }

  .select-year {
    width: 255px;
    position: relative;
    z-index: 1;
    transition-duration: 0.3s;

    .btn-select {
      transition-duration: 0.3s;
      background: #eee;
      padding: 10px 21px;
      width: 255px;
      border-radius: 50px 50px 50px 50px;
    }

    .btn-select.opened {
      border-radius: 16px 16px 0 0;
    }

    .group-years {
      top: 46px;
      position: absolute;
      background: #eee;
      height: 0;
      border-radius: 0 0 16px 16px;
      padding: 0 10px;
      transition-duration: 0.3s;

      > div {
        height: 100%;

        .btn-years {
          padding: 15px 0;
          transition-duration: 0.3s;

          + .btn-years {
            border-top: 1px solid #ccc !important;
          }
        }

        .btn-years.selected,
        .btn-years:hover {
          color: #202020;
        }
      }
    }

    .group-years.opened {
      padding: 10px 10px;
      height: 250px;
    }
  }

  .btn-option:hover,
  .btn-option.selected {
    -webkit-text-fill-color: #f15a29;
    -webkit-text-stroke-color: #f15a29;

    ::after {
      opacity: 1;
    }
  }

  .btn-add {
    background: #f9f9f9;
    border: 1px solid #e4e4e4;
    border-radius: 20px;
    height: 294px;
    transition-duration: 0.3s;

    svg {
      color: #a3a3a3;
      transition-duration: 0.3s;
    }

    span {
      color: #a3a3a3;
      transition-duration: 0.3s;
    }

    :hover {
      background: ${darken(0.03, '#f9f9f9')};

      svg {
        color: #777777;
      }

      span {
        color: #777777;
      }
    }
  }
`;

export const Menu = styled.div<IMenu>`
  border-radius: 20px;
  background-image: url(${bgMenu});
  background-color: ${(props) =>
    props.default ? `${props.bgcolor}80` : props.bgcolor};
  background-position: center;
  background-size: cover;
  height: 294px;
  overflow: hidden;

  p {
    color: #fff;
  }

  .content {
    opacity: ${(props) => (props.default ? 0.5 : 1)};
  }

  .options {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;

    a,
    button {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      transition-duration: 0.3s;

      :nth-child(1) {
        span {
          color: #c9e2ff;
        }
      }

      :nth-child(2) {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;

        span {
          color: #fff;
        }
      }

      :nth-child(3) {
        span {
          color: #ff1a50;
        }
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  :hover {
    .options {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const MenusGroup = styled.div<IMenusGroup>`
  margin-top: -1px;
  overflow: hidden;
  height: ${(props) => (props.opened ? 1520 : 0)}px;
  transition-duration: 0.3s;

  .all {
    border: 1px solid #e2e2e2;
    border-radius: 0 0 10px 10px;
  }
`;

export const BtnTitle = styled.button<IBtnTitle>`
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  transition-duration: 0.3s;
  border-radius: ${(props) => (props.opened ? '10px 10px 0 0' : '10px')};

  svg {
    transition-duration: 0.3s;
    transform: rotateZ(${(props) => (props.opened ? '0' : '90deg')});
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;
    }

    .input-file {
      position: relative;
      height: unset;
      min-height: 46px;
      padding: 10px 0;

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }
  }
`;
