import styled from 'styled-components';
import { darken } from 'polished';

interface IAvatar {
  src: string;
}

interface IOptions {
  active: boolean;
}

export const Container = styled.div`
  .table-galleries {
    padding-top: 0;

    > div {
      .content {
        padding-top: 10px;
        overflow: unset;

        .tb-header {
          border: none;

          button {
            color: #202020;
          }
        }

        .tb-body {
          .tb-row {
            transition-duration: 0.3s;
            border-radius: 16px;

            :hover {
              background-color: ${darken(0.03, '#fff')};
            }
          }

          div {
            color: #707070;
          }
        }

        .tb-header,
        .tb-body {
          width: 100%;

          .tb-row {
            width: 100%;

            > button:nth-child(1),
            > div:nth-child(1) {
              width: 100px;
            }

            > button:nth-child(2),
            > div:nth-child(2) {
              width: 400px;
            }

            > button:nth-child(3),
            > div:nth-child(3) {
              width: 250px;
            }

            > button:nth-child(4),
            > div:nth-child(4) {
              width: calc(100% - 800px);

              .options-group {
                width: max-content;
                margin-left: auto;
              }
            }
          }
        }
      }

      :after,
      :before {
        border: none;
      }

      :nth-child(2) {
        select {
          color: #bbbbbb;
        }

        small {
          color: #bbbbbb;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .table-suppliers {
      > div {
        .content {
          overflow: unset;
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

export const Options = styled.div<IOptions>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  background: #fff;
  border: 2px solid #e4e4e4;
  width: max-content;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -110px;
  left: 10px;
  transform: translateX(-50%);

  a,
  button {
    background: transparent;
    border: none;
    text-align: center;
    color: #202020;
    padding: 8px 0;

    + button,
    + a {
      border-top: 1px solid #e4e4e4;
    }
  }

  :after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    border-right: 2px solid #e4e4e4;
    border-bottom: 2px solid #e4e4e4;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: rotateZ(45deg) translateX(-50%);
  }
`;
