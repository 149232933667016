import React, { useCallback, useEffect, useState } from 'react';

import { HiPhoto } from 'react-icons/hi2';
import { VscInfo } from 'react-icons/vsc';
import { Container, Preview, Placeholder } from './styles';
import Input from '../Input';

interface IInputPhoto {
  name: string;
  className?: string;
  placeholder?: string;
  onChange?(photo: File | undefined): void;
  value?: string;
  error?: string;
  hasError?: boolean;
}

const InputPhoto: React.FC<IInputPhoto> = ({
  name,
  className,
  onChange,
  placeholder = 'Clique aqui para adicionar uma foto',
  value,
  error,
  hasError,
}) => {
  const [preview, setPreview] = useState('');

  const handleChange = useCallback(
    (e) => {
      if (e.target.files[0]) {
        setPreview(URL.createObjectURL(e.target.files[0]));
        if (onChange) {
          onChange(e.target.files[0]);
        }
      } else {
        setPreview('');
        if (onChange) {
          onChange(undefined);
        }
      }
    },
    [onChange]
  );

  return (
    <Container hasError={hasError} className={className}>
      {preview || value ? (
        <Preview src={preview || value || preview} hasError={hasError} />
      ) : (
        <Placeholder hasError={hasError}>
          <div>
            <HiPhoto size={32} color="#a3a3a3" />
            <p>{placeholder}</p>
          </div>
        </Placeholder>
      )}
      <Input
        type="file"
        name={name}
        className="d-none"
        onChange={handleChange}
      />
      {error && (
        <div className="w-100 d-flex align-items-center mt-1 error-box">
          <VscInfo size={24} color="#FF1A50" className="me-1" />
          <span className="small text-danger error mb-0">{error}</span>
        </div>
      )}
    </Container>
  );
};

export default InputPhoto;
