import React, { useCallback, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import List from 'react-chatview';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container } from './styles';
import ShowProcess from '../ShowProcess';

import { IProcess, IProcessResponse } from '..';

interface IParams {
  type: string;
}

const ProcessesInProgress: React.FC = () => {
  const params = useParams<IParams>();
  const [recents, setRecents] = useState<IProcess[]>([]);
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleLoadProcesses = useCallback(
    async (page) => {
      const response = await api.get<IProcessResponse>('processes', {
        params: {
          status: 'Em andamento',
          page,
          isSupplier: params.type === 'fornecedores',
        },
      });
      const data = response.data.data.map((process) => {
        let nurseries = process.processes_nurseries.map(
          (processNursery) => processNursery.nursery.name
        );
        nurseries = nurseries.map((nursery) => {
          const nameParts = nursery.split(' ');
          return `${nameParts[nameParts.length - 2]} ${
            nameParts[nameParts.length - 1]
          }`;
        });

        return {
          id: process.id,
          title: process.title,
          status: process.status,
          nurseries,
          expiry_date: process.expiry_date,
          created_at: format(parseISO(process.created_at), 'dd/MM/yyyy'),
          archives: [],
          quotations: [],
        };
      });

      if (page === 1) {
        setProcesses(data);
      } else {
        setProcesses((state) => [...state, ...data]);
      }
      setTotal(response.data.total);
      setTotalPages(response.data.last_page);
    },
    [params.type]
  );

  useEffect(() => {
    handleLoadProcesses(1);
  }, [handleLoadProcesses]);

  const handleLoad = useCallback(async () => {
    if (pageSelected < totalPages) {
      await handleLoadProcesses(pageSelected + 1);
      setPageSelected(pageSelected + 1);
    }
  }, [handleLoadProcesses, pageSelected, totalPages]);

  return (
    <Container>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 mt-5 mb-3 d-lg-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Link
                to={`${process.env.PUBLIC_URL}/processos/${params.type}`}
                className="p-3 me-2"
              >
                <FiArrowLeft size={36} color="#202020" />
              </Link>
              <h1 className="h3 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
                Processos em Andamento -{' '}
                {`${params.type.charAt(0).toUpperCase()}${params.type.slice(
                  1
                )}`}
              </h1>
            </div>
            <p className="process-qtd px-3 py-2 text-center mb-0">
              Quantidade: <b>{total.toString().padStart(2, '0')}</b> Processos
            </p>
          </div>
          {recents.map((process) => (
            <ShowProcess
              type="recent"
              process={process}
              onFinalizeProcess={() => handleLoadProcesses(1)}
              onSucceededDeleteProcess={() => handleLoadProcesses(1)}
              className="col-lg-4"
            />
          ))}
        </div>
        <div className="row">
          <List
            className="col-12 mt-4 mb-5 quotations"
            scrollLoadThreshold={100}
            onInfiniteLoad={handleLoad}
          >
            {processes.map((process) => (
              <ShowProcess
                type="old"
                process={process}
                onFinalizeProcess={() => handleLoadProcesses(1)}
                onSucceededDeleteProcess={() => handleLoadProcesses(1)}
                className="mb-3"
              />
            ))}
          </List>
        </div>
      </div>
    </Container>
  );
};

export default ProcessesInProgress;
