import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RiArrowDownSLine, RiQuestionLine } from 'react-icons/ri';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputPhoto from '~/components/InputPhoto';
import Textarea from '~/components/Textarea';

import api from '~/services/api';
import Toast from '~/utils/toast';

interface IParams {
  nurseryId: string;
}

interface IAddress {
  id: number;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
  complement: string;
}

interface IArchive {
  id: number;
  type: string;
  title: string;
  archive_url: string;
}

interface INurseryResponse {
  id: number;
  cnpj: string;
  name: string;
  email: string;
  primary_phone: string;
  secondary_phone: string;
  story: string;
  slug: string;
  banner: {
    archive_url: string;
  };
  address: IAddress;
  archives: IArchive[];
}

interface IFormData {
  cnpj: string;
  name: string;
  email: string;
  primary_phone: string;
  secondary_phone: string;
  aboutSchool: string;
  mission: string;
  vision: string;
  value: string;
  zipcode: string;
  street: string;
  number: string;
  state: string;
  city: string;
  neighborhood: string;
  complement: string;
}

const NurseryUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [nursery, setNursery] = useState({} as IFormData);
  const [banner, setBanner] = useState<File | undefined>();
  const [bannerOld, setBannerOld] = useState({} as IArchive);
  const [bannerError, setBannerError] = useState('');
  const [missionPhoto, setMissionPhoto] = useState<File | undefined>();
  const [missionOld, setMissionOld] = useState({} as IArchive);
  const [missionPhotoError, setMissionPhotoError] = useState('');
  const [visionPhoto, setVisionPhoto] = useState<File | undefined>();
  const [visionOld, setVisionOld] = useState({} as IArchive);
  const [visionPhotoError, setVisionPhotoError] = useState('');
  const [valuePhoto, setValuePhoto] = useState<File | undefined>();
  const [valueOld, setValueOld] = useState({} as IArchive);
  const [valuePhotoError, setValuePhotoError] = useState('');
  const [addressId, setAddressId] = useState(0);
  const [zipcode, setZipcode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [complement, setComplement] = useState('');

  useEffect(() => {
    api
      .get<INurseryResponse>(`nurseries/${params.nurseryId}`)
      .then((response) => {
        const [bannerData, missionData, visionData, valueData] =
          response.data.archives.filter(
            (archive) =>
              archive.type === 'aboutUs' &&
              (archive.title === 'banner' ||
                archive.title === 'mission' ||
                archive.title === 'vision' ||
                archive.title === 'value')
          );
        const [aboutSchool, mission, vision, value] =
          response.data.story.split('#*;');
        setNursery({
          cnpj: response.data.cnpj,
          name: response.data.name,
          email: response.data.email,
          primary_phone: response.data.primary_phone,
          secondary_phone: response.data.secondary_phone,
          aboutSchool,
          mission,
          vision,
          value,
          zipcode: response.data.address.zip_code,
          street: response.data.address.street,
          number: response.data.address.number,
          state: response.data.address.state,
          city: response.data.address.city,
          neighborhood: response.data.address.neighborhood,
          complement: response.data.address.complement,
        });
        setBannerOld(bannerData);
        setMissionOld(missionData);
        setVisionOld(visionData);
        setValueOld(valueData);
        setAddressId(response.data.address.id);
        setZipcode(response.data.address.zip_code);
        setStreet(response.data.address.street);
        setNumber(response.data.address.number);
        setNeighborhood(response.data.address.neighborhood);
        setCity(response.data.address.city);
        setState(response.data.address.state);
        setComplement(response.data.address.complement);
      });
  }, [params.nurseryId]);

  const handleChangeBanner = useCallback((file) => {
    setBanner(file);
  }, []);

  const handleChangeMissionPhoto = useCallback((file) => {
    setMissionPhoto(file);
  }, []);

  const handleChangeVisionPhoto = useCallback((file) => {
    setVisionPhoto(file);
  }, []);

  const handleChangeValuePhoto = useCallback((file) => {
    setValuePhoto(file);
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    try {
      if (e.target.value.length === 9) {
        const zipcodeDoc = e.target.value.replace('-', '');
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipcodeDoc}/json/`
        );
        setStreet(response.data.logradouro);
        setNeighborhood(response.data.bairro);
        setCity(response.data.localidade);
        setState(response.data.uf);
        setComplement(response.data.complemento);
      }

      setZipcode(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setBannerError('');
        setMissionPhotoError('');
        setVisionPhotoError('');
        setValuePhotoError('');

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('O CNPJ é obrigatório'),
          name: Yup.string().required('O nome é obrigatória'),
          email: Yup.string().required('O e-email é obrigatório'),
          primary_phone: Yup.string().required(
            'O telefone principal é obrigatório'
          ),
          aboutSchool: Yup.string().required('Sobre a escola é obrigatório'),
          mission: Yup.string().required('A missão é obrigatória'),
          vision: Yup.string().required('A visão é obrigatória'),
          value: Yup.string().required('O valor é obrigatório'),
          zipcode: Yup.string().required('O CEP é obrigatório'),
          street: Yup.string().required('A rua é obrigatória'),
          number: Yup.string().required('O número é obrigatório'),
          state: Yup.string().required('O estado é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          neighborhood: Yup.string().required('O bairro é obrigatório'),
          complement: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          cnpj: data.cnpj,
          name: data.name,
          email: data.email,
          primary_phone: data.primary_phone,
          secondary_phone: data.secondary_phone,
          story: `${data.aboutSchool}#*;${data.mission}#*;${data.vision}#*;${data.value}`,
        };

        const response = await api.put(
          `nurseries/${params.nurseryId}`,
          formData
        );

        const addressFormData = {
          nursery_id: response.data.id,
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          zip_code: data.zipcode,
          complement: data.complement,
        };

        await api.put(`addresses/${addressId}`, addressFormData);

        if (banner) {
          const bannerFormData = new FormData();
          bannerFormData.append('banner', banner as File);
          bannerFormData.append('nursery_id', response.data.id);
          bannerFormData.append('type', 'aboutUs');
          bannerFormData.append('title', 'banner');
          await api.post(`archives/${bannerOld.id}`, bannerFormData);
        }

        if (missionPhoto) {
          const missionFormData = new FormData();
          missionFormData.append('mission', missionPhoto as File);
          missionFormData.append('nursery_id', response.data.id);
          missionFormData.append('type', 'aboutUs');
          missionFormData.append('title', 'mission');
          await api.post(`archives/${missionOld.id}`, missionFormData);
        }

        if (visionPhoto) {
          const visionFormData = new FormData();
          visionFormData.append('vision', visionPhoto as File);
          visionFormData.append('nursery_id', response.data.id);
          visionFormData.append('type', 'aboutUs');
          visionFormData.append('title', 'vision');
          await api.post(`archives/${visionOld.id}`, visionFormData);
        }

        if (valuePhoto) {
          const valueFormData = new FormData();
          valueFormData.append('value', valuePhoto as File);
          valueFormData.append('nursery_id', response.data.id);
          valueFormData.append('type', 'aboutUs');
          valueFormData.append('title', 'value');
          await api.post(`archives/${valueOld.id}`, valueFormData);
        }
        Toast.fire({
          icon: 'success',
          title: 'Maternal editado com sucesso!',
        });
        history.push(`${process.env.PUBLIC_URL}/maternais`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          console.log(errors);

          if (errors.banner) {
            setBannerError(errors.banner);
          }

          if (errors.missionPhoto) {
            setMissionPhotoError(errors.missionPhoto);
          }

          if (errors.visionPhoto) {
            setVisionPhotoError(errors.visionPhoto);
          }

          if (errors.valuePhoto) {
            setValuePhotoError(errors.valuePhoto);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      addressId,
      banner,
      bannerOld,
      history,
      missionOld,
      missionPhoto,
      params.nurseryId,
      valueOld,
      valuePhoto,
      visionOld,
      visionPhoto,
    ]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row align-items-center px-3 px-sm-0">
          <div className="col-6">
            <h1 className="text-secondary">Editar maternal</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="box py-3 px-3 px-lg-5">
              <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="white-box w-100 py-5 mt-5"
                  initialData={nursery}
                >
                  <div className="form-box">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            CNPJ
                          </span>
                          <InputMask
                            kind="cnpj"
                            name="cnpj"
                            placeholder="Digite aqui"
                            value={nursery.cnpj}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Nome
                          </span>
                          <Input name="name" placeholder="Digite aqui" />
                        </label>
                      </div>
                      <div className="col-12">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            E-mail
                          </span>
                          <Input
                            type="email"
                            name="email"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Telefone Principal
                          </span>
                          <InputMask
                            kind="cel-phone"
                            name="primary_phone"
                            placeholder="Digite aqui"
                            value={nursery.primary_phone}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Telefone Secundário
                          </span>
                          <InputMask
                            kind="cel-phone"
                            name="secondary_phone"
                            placeholder="Digite aqui"
                            value={nursery.secondary_phone}
                          />
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Banner
                            </span>
                          </label>
                          <InputPhoto
                            name="banner"
                            onChange={handleChangeBanner}
                            value={bannerOld?.archive_url}
                          />
                        </div>
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Sobre a escola
                          </span>
                          <Textarea
                            name="aboutSchool"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Missão
                          </span>
                          <Textarea
                            name="mission"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              &nbsp;
                            </span>
                          </label>
                          <InputPhoto
                            name="missionPhoto"
                            onChange={handleChangeMissionPhoto}
                            value={missionOld?.archive_url}
                            className="sub-photos"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Visão
                          </span>
                          <Textarea
                            name="vision"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              &nbsp;
                            </span>
                          </label>
                          <InputPhoto
                            name="visionPhoto"
                            onChange={handleChangeVisionPhoto}
                            value={visionOld?.archive_url}
                            className="sub-photos"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Valor
                          </span>
                          <Textarea
                            name="value"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              &nbsp;
                            </span>
                          </label>
                          <InputPhoto
                            name="valuePhoto"
                            onChange={handleChangeValuePhoto}
                            value={valueOld?.archive_url}
                            className="sub-photos"
                          />
                        </div>
                      </div>
                      <div className="col-12 mt-5">
                        <h2 className="text-secondary">Endereço</h2>
                      </div>
                      <div className="col-lg-3">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            CEP
                          </span>
                          <InputMask
                            kind="zip-code"
                            name="zipcode"
                            placeholder="Digite aqui"
                            onChange={handleChangeZipCode}
                            value={zipcode}
                          />
                        </label>
                      </div>
                      <div className="col-lg-9">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Rua
                          </span>
                          <Input
                            name="street"
                            placeholder="Digite aqui"
                            onChange={(e) => setStreet(e.target.value)}
                            value={street}
                          />
                        </label>
                      </div>
                      <div className="col-lg-3">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Número
                          </span>
                          <Input
                            name="number"
                            placeholder="Digite aqui"
                            onChange={(e) => setNumber(e.target.value)}
                            value={number}
                          />
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Estado
                          </span>
                          <Input
                            name="state"
                            placeholder="Digite aqui"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                        </label>
                      </div>
                      <div className="col-lg-5">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Cidade
                          </span>
                          <Input
                            name="city"
                            placeholder="Digite aqui"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Bairro
                          </span>
                          <Input
                            name="neighborhood"
                            placeholder="Digite aqui"
                            onChange={(e) => setNeighborhood(e.target.value)}
                            value={neighborhood}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Complemento
                          </span>
                          <Input
                            name="complement"
                            placeholder="Digite aqui"
                            onChange={(e) => setComplement(e.target.value)}
                            value={complement}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-75 mx-auto d-flex justify-content-end mt-3 mt-lg-5">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill py-2 px-4 w-100 w-lg-auto"
                    >
                      Continuar
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NurseryUpdate;
