import React from 'react';

import PoweredBy from '../PoweredBy';

const Footer: React.FC = () => {
  return (
    <>
      <PoweredBy />
    </>
  );
};

export default Footer;
