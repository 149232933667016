import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TiArrowRight } from 'react-icons/ti';
import { Link, useParams } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';

import { Container } from './styles';
import { useResize } from '~/hooks/Resize';
import NewProcess from './NewProcess';
import ShowProcess from './ShowProcess';

interface IQuotation {
  id: string;
  supplier_id: string;
  process_id: string;
  accepted_at?: string;
  refused_at?: string;
  reason?: string;
}

export interface IArchive {
  id: string;
  title: string;
  name: string;
  archive_url: string;
  quotation?: IQuotation;
  created_at: string;
}

export interface IProcessDataResponse {
  id: number;
  title: string;
  status: 'Em aberto' | 'Em andamento' | 'Finalizado';
  processes_nurseries: {
    id: number;
    nursery: {
      name: string;
    };
  }[];
  expiry_date: string;
  created_at: string;
  archives: IArchive[];
}

export interface IProcess {
  id: number;
  title: string;
  status: 'Em aberto' | 'Em andamento' | 'Finalizado';
  nurseries: string[];
  expiry_date: string;
  created_at: string;
  archives: IArchive[];
}

export interface IProcessResponse {
  data: IProcessDataResponse[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IQuantities {
  open: number;
  inProcess: number;
  finished: number;
}

interface IParams {
  type: string;
}

const Dashboard: React.FC = () => {
  const params = useParams<IParams>();
  const { width } = useResize();
  const [quantities, setQuantities] = useState<IQuantities>({
    open: 0,
    inProcess: 0,
    finished: 0,
  });
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const pages = useMemo(() => {
    const qtd = width > 992 ? 7 : 5;
    const pageArray = Array.from(
      { length: totalPages < qtd ? totalPages : qtd },
      (e, index) => (totalPages < qtd ? `${index + 1}`.padStart(2, '0') : e)
    );

    const data = pageArray.map((page, index) => {
      if (page) {
        return page;
      }

      if (width > 992) {
        if (index === 0) {
          return '01';
        }
        if (index === 1) {
          if (pageSelected > 3 && pageSelected - 3 !== 1) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 4).toString().padStart(2, '0');
            }
            return (pageSelected - 1).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected > 4) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 3).toString().padStart(2, '0');
            }
            return pageSelected.toString().padStart(2, '0');
          }
          return (4).toString().padStart(2, '0');
        }
        if (index === 4) {
          if (pageSelected >= 5) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 2).toString().padStart(2, '0');
            }
            return (pageSelected + 1).toString().padStart(2, '0');
          }
          return (5).toString().padStart(2, '0');
        }
        if (index === 5) {
          if (pageSelected + 3 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 6) {
          return totalPages.toString().padStart(2, '0');
        }
      } else {
        if (index === 0) {
          return (1).toString().padStart(2, '0');
        }
        if (index === 1) {
          if (pageSelected > 3) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3 && pageSelected + 2 < totalPages) {
            return pageSelected.toString().padStart(2, '0');
          }
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 2).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 4) {
          return totalPages.toString().padStart(2, '0');
        }
      }

      return '';
    });

    return data as string[];
  }, [pageSelected, totalPages, width]);

  const handleLoadProcesses = useCallback(
    async (page) => {
      const response = await api.get<IProcessResponse>('processes', {
        params: {
          page,
          isSupplier: params.type === 'fornecedores',
        },
      });

      const data = response.data.data.map((process) => {
        let nurseries = process.processes_nurseries.map(
          (processNursery) => processNursery.nursery.name
        );
        nurseries = nurseries.map((nursery) => {
          const nameParts = nursery.split(' ');
          return `${nameParts[nameParts.length - 2]} ${
            nameParts[nameParts.length - 1]
          }`;
        });

        return {
          id: process.id,
          title: process.title,
          status: process.status,
          expiry_date: process.expiry_date,
          nurseries,
          created_at: format(parseISO(process.created_at), 'dd/MM/yyyy'),
          archives: [],
          quotations: [],
        };
      });
      setTotalPages(response.data.last_page);
      setProcesses(data);
    },
    [params.type]
  );

  useEffect(() => {
    handleLoadProcesses(pageSelected).then(async () => {
      const response = await api.get('processes/quantities', {
        params: {
          isSupplier: params.type === 'fornecedores',
        },
      });
      setQuantities(response.data);
    });
  }, [handleLoadProcesses, pageSelected, params.type]);

  const handleClickPrev = useCallback(() => {
    setPageSelected((state) => (state - 1 <= 1 ? 1 : state - 1));
  }, []);

  const handleClickPage = useCallback((page) => {
    setPageSelected(page);
  }, []);

  const handleClickNext = useCallback(() => {
    setPageSelected((state) =>
      state + 1 >= totalPages ? totalPages : state + 1
    );
  }, [totalPages]);

  const handleRefreshProcesses = useCallback(async () => {
    await handleLoadProcesses(pageSelected);
    const response = await api.get('processes/quantities', {
      params: {
        isSupplier: params.type === 'fornecedores',
      },
    });
    setQuantities(response.data);
  }, [handleLoadProcesses, pageSelected, params.type]);

  return (
    <Container className="pb-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
            <h1 className="h3 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
              Processos{' '}
              {`${params.type.charAt(0).toUpperCase()}${params.type.slice(1)}`}
            </h1>
            <NewProcess onSucceededAddNewProcess={handleRefreshProcesses} />
          </div>
          <Link
            to={`${process.env.PUBLIC_URL}/processos/${params.type}/abertos`}
            className="col-lg-4 mb-3 mb-lg-0"
          >
            <div className="box-process open">
              <div className="pt-5 px-4 pb-4">
                <p className="display-4 fw-semibold text-white">
                  {quantities.open.toString().padStart(2, '0')}
                </p>
                <p className="text-white h5">
                  Processos <b className="d-block">Abertos</b>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
                <p className="text-white mb-0 me-2">Ver processos</p>
                <div>
                  <TiArrowRight size={18} color="#fff" />
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/processos/${params.type}/andamento`}
            className="col-lg-4 mb-3 mb-lg-0"
          >
            <div className="box-process in-progress">
              <div className="pt-5 px-4 pb-4">
                <p className="display-4 fw-semibold text-white">
                  {quantities.inProcess.toString().padStart(2, '0')}
                </p>
                <p className="text-white h5">
                  Processos em <b className="d-block">Andamento</b>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
                <p className="text-white mb-0 me-2">Ver processos</p>
                <div>
                  <TiArrowRight size={18} color="#fff" />
                </div>
              </div>
            </div>
          </Link>
          <Link
            to={`${process.env.PUBLIC_URL}/processos/${params.type}/finalizados`}
            className="col-lg-4 mb-3 mb-lg-0"
          >
            <div className="box-process finished">
              <div className="pt-5 px-4 pb-4">
                <p className="display-4 fw-semibold text-white">
                  {quantities.finished.toString().padStart(2, '0')}
                </p>
                <p className="text-white h5">
                  Processos <b className="d-block">Finalizados</b>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
                <p className="text-white mb-0 me-2">Ver processos</p>
                <div>
                  <TiArrowRight size={18} color="#fff" />
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 mb-2 mb-lg-0">
            <h2 className="h4">Lista dos processos</h2>
          </div>
          <div className="col-12 mt-4 mb-5">
            {processes.map((process) => (
              <ShowProcess
                process={process}
                type="old"
                className="mb-3"
                onAccept={handleRefreshProcesses}
                onFinalizeProcess={handleRefreshProcesses}
                onSucceededUpdateProcess={handleRefreshProcesses}
                onSucceededDeleteProcess={handleRefreshProcesses}
              />
            ))}
          </div>
          <div className="col-12 d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickPrev}
              >
                <RiArrowLeftSLine size={24} color="#707070" />
              </button>
              {pages.map((page, index) => (
                <button
                  key={index.toString()}
                  type="button"
                  className={`btn btn-pagination ${
                    page === pageSelected.toString().padStart(2, '0')
                      ? 'selected'
                      : ''
                  } ${page === '...' ? 'ellipsis' : ''}`}
                  onClick={() => handleClickPage(parseInt(page, 10))}
                  disabled={page === '...'}
                >
                  {page}
                </button>
              ))}
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickNext}
              >
                <RiArrowRightSLine size={24} color="#707070" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
