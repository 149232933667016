import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RiArrowDownSLine, RiQuestionLine } from 'react-icons/ri';
import axios from 'axios';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import InputPhoto from '~/components/InputPhoto';
import Textarea from '~/components/Textarea';
import Select, { IOption } from '~/components/Select';

import api from '~/services/api';
import InputTags from '~/components/InputTags';
import Toast from '~/utils/toast';

interface IParams {
  projectId: string;
}

interface INursery {
  id: number;
  name: string;
}

interface IArchive {
  id: number;
  archive_url: string;
}

interface IProjectResponse {
  id: number;
  nursery_id: number;
  title: string;
  content: string;
  activities?: string;
  archive: IArchive;
}

interface IFormData {
  title: string;
  content: string;
  activities: string;
  nursery: string;
}

const ProjectUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [nurseries, setNurseries] = useState<IOption[]>([]);
  const [project, setProjetct] = useState({} as IFormData);
  const [photo, setPhoto] = useState<File | undefined>();
  const [photoData, setPhotoData] = useState({} as IArchive);
  const [photoError, setPhotoError] = useState('');

  useEffect(() => {
    api
      .get<IProjectResponse>(`projects/${params.projectId}`)
      .then((response) => {
        setProjetct({
          title: response.data.title,
          content: response.data.content,
          nursery: response.data.nursery_id.toString(),
          activities: response.data.activities || '',
        });
        setPhotoData(response.data.archive);
      });
  }, [params.projectId]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<IOption>((nursery) => ({
          id: nursery.id,
          value: nursery.name,
          selected: project.nursery === nursery.id.toString(),
          notSelectable: false,
        }));
        data.unshift({
          id: '',
          value: 'selecionar',
          selected: false,
          notSelectable: true,
        });
        setNurseries(data);
      });
  }, [project.nursery]);

  const handleChangePhoto = useCallback((file) => {
    setPhoto(file);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      console.log(data);
      try {
        formRef.current?.setErrors({});
        setPhotoError('');

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          content: Yup.string().required('O conteudo é obrigatório'),
          nursery: Yup.string().required('O maternal é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          nursery_id: data.nursery,
          title: data.title,
          content: data.content,
          activities: data.activities,
        };

        const response = await api.put(
          `projects/${params.projectId}`,
          formData
        );

        if (photo) {
          const photoFormData = new FormData();
          photoFormData.append('project', photo);
          photoFormData.append('projetct_id', response.data.id);
          await api.post(`archives/${photoData.id}`, photoFormData);
        }

        Toast.fire({
          icon: 'success',
          title: 'Projeto editado com sucesso!',
        });

        history.push(`${process.env.PUBLIC_URL}/projetos`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (errors.photo) {
            setPhotoError(errors.photo);
          }
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [history, params.projectId, photo, photoData]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row align-items-center px-3 px-sm-0">
          <div className="col-6">
            <h1 className="text-secondary">Cadastrar projeto</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="box py-3 px-3 px-lg-5">
              <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-3 pb-5">
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="white-box w-100 py-5 mt-5"
                  initialData={project}
                >
                  <div className="form-box">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Título
                          </span>
                          <Input name="title" placeholder="Digite aqui" />
                        </label>
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Conteúdo
                          </span>
                          <Textarea
                            name="content"
                            placeholder="Digite aqui"
                            rows={6}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Foto
                            </span>
                            <InputPhoto
                              name="photo"
                              className="input-photo"
                              onChange={handleChangePhoto}
                              hasError={!!photoError}
                              value={photoData?.archive_url}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="w-100">
                            <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                              Atividades
                            </span>
                          </label>
                          <InputTags
                            name="activities"
                            value={project.activities}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="mb-3 w-100">
                          <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                            Maternal
                          </span>
                          <Select
                            name="nursery"
                            placeholder="Digite aqui"
                            onlySelect
                            options={nurseries}
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-75 mx-auto d-flex justify-content-end mt-3 mt-lg-5">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill py-2 px-4 w-100 w-lg-auto"
                    >
                      Continuar
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProjectUpdate;
