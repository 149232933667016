import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 46px;

  .input-tag {
    flex-grow: 1;
    min-width: 150px;
    padding: 10px 0;
  }
`;

export const Tag = styled.div`
  background-color: #d1d1d0;
  color: #202020;
  border-radius: 100px;
  padding: 10px 20px;
  margin: 7.5px 0 7.5px 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media screen and (min-width: 992px) {
    font-size: 18px;
  }

  + div {
    margin-left: 7.5px;
  }

  + input {
    padding: 10px 20px !important;
  }
`;
