import styled from 'styled-components';
import InputFile from '~/components/InputFile';

interface IInputPhotos {
  height: number;
  hasPhotos: boolean;
}

interface IImg {
  src: string;
}

export const Container = styled.div`
  min-height: calc(100vh - 110px);

  .logo img {
    width: 42px;
    height: 42px;
  }

  .box {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;

    h1 {
      color: #202020;
    }

    > p {
      width: 55%;
    }

    form.white-box {
      border-radius: 21px;
      border: 1px solid #d7d7d7;
      background: #fff;

      .form-box {
        width: 80%;
        margin: 0 auto;

        .photos-preview {
          background-color: #f9f9f9;
          border: 2px dashed #e4e4e4;
          border-top: none;
          border-radius: 0 0 10px 10px;
        }
      }
    }

    .btn-submit {
      background: #ad2d03;
      color: #fff;
    }
  }

  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 70px);

    .logo img {
      width: 63px;
      height: 63px;
    }

    .box {
      form.white-box {
        .form-box {
          width: 90%;

          .input-file {
            .error-box {
              .error {
                font-size: 90%;
              }
            }
          }

          .small-input-file {
            .error-box {
              bottom: -45px;
            }
          }
        }
      }
    }
  }
`;

export const InputPhotos = styled(InputFile)<IInputPhotos>`
  border-width: ${(props) => (props.hasPhotos ? 0 : 2)}px;
  border-style: dashed;
  transition-duration: 0.3s;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => (props.hasPhotos ? '10px 10px 0 0' : '10px')};
  background-color: ${(props) => (props.hasPhotos ? '#F15A29' : '#f9f9f9')};

  label {
    width: 100%;
    height: 100%;
    transition-duration: 0.3s;

    div {
      height: 100%;
      transition-duration: 0.3s;
    }
  }
`;

export const Img = styled.div<IImg>`
  width: 100%;
  height: 150px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  button {
    position: relative;
    z-index: 2;
    opacity: 0;
    transition-duration: 0.3s;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition-duration: 0.3s;
  }

  :hover {
    button {
      opacity: 1;
    }

    ::before {
      opacity: 1;
    }
  }
`;
